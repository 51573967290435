import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ShrinkMenuIcon from '@material-ui/icons/MenuOpen';

import Settings from '../settings'
import Login from '../settings/login-button'
import User from '../user'
import actions from '../../lib/actions'
import selectors from '../../store/selectors'
import constants from '../../lib/constants'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: constants.COLORS.BLUE,
    zIndex: theme.zIndex.drawer + 1,
    maxHeight: '64px',
    overflow: 'hidden'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    fontSize: '2rem'
  },
  title: {
    flexGrow: 1,
  },
  user: {
    minWidth: '17rem'
  },
  login: {

  }
}))

const Header = props => {
  const { userType, thisUser, title, shrinkSidebar, expandSidebar, sidebarExpanded } = props
  const classes = useStyles()
  return (
  <div className={classes.root}>
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar>
        {thisUser && userType === constants.MEMBER_TYPES.MODERATOR ?
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
            onClick={() => {
              sidebarExpanded ? shrinkSidebar() : expandSidebar()
            }}
          >
            {sidebarExpanded ? <ShrinkMenuIcon className={classes.menuIcon}/> : <MenuIcon className={classes.menuIcon}/>}
          </IconButton> : null
        }
        <Typography variant='h6' className={classes.title}>
          {title}
        </Typography>
        {thisUser && thisUser.userId ?
          <div className={classes.user}>
            <User userId={thisUser.userId} notificationCount={0} hasNew={false}/>
          </div> : <div className={classes.login}><Login isTextButton={true} noPadding={true}/> to Join Discussion </div>}
        {thisUser ? <Settings/> : null}
      </Toolbar>
    </AppBar>
  </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    shrinkSidebar: () => {
      dispatch(actions.shrinkMainSidebar())
    },
    expandSidebar: () => {
      dispatch(actions.expandMainSidebar())
    },
  }
}

const mapStateToProps = state => {
  const eventData = selectors.getEventData(state)
  const { title } = eventData
  const thisUser = selectors.getCurrentMember(state)
  const userType = selectors.getThisMemberType(state)
  const isShowingMobile = state.sidebar.showInMobile
  const sidebarExpanded = state.sidebar.mainExpanded
  return { userType, thisUser, isShowingMobile, title, sidebarExpanded }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)