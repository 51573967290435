import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'

import Attachments from './attachments'
import Poll from '../poll'
import selectors from '../../../store/selectors'
import constants from '../../../lib/constants'

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'pre',
    textAlign: 'start'
  },
  reverse: {
    flexDirection: 'row-reverse',
    textAlign: 'end'
  },
  published: {
    flexDirection: 'column',
  },
  theirMessageContainer: {
    width: '100%',
    display: 'inline-block'
  },
  theirMessage: {
    float:'right',
    boxShadow: '-12px 0px 0px 0px #ddd',
    paddingLeft: '0.5rem',
    backgroundColor: '#f9f9f9',
    background: 'linear-gradient(90deg, rgb(245,245,245), rgb(255,255,255))',
    borderRadius: '2px',
  },
  divider: {
    marginTop: '0.5rem',
    width: '100%',
    marginLeft: '-0.5rem'
  },
}))

/**
 * combines the given links into the message as anchor tags that open in a new window
 * @param {string} props.text the message to mix with links
 * @param {object[]} props.links an array of link objects with linkText, locationIndex, location keys
 */
const MessageText = props => {
  let { text, links } = props
  let messageText = text
  const components = []
  let count = 0
  while(links.length && count < 100) {
    count += 1
    const nextLink = links.shift()
    const nextMessageText = messageText.substring(0, nextLink.locationIndex)
    components.push(<div key={'message text piece' + count + text} style={{display:'contents'}}>{nextMessageText}</div>)
    const safeHref = encodeURI(nextLink.location).replace(/\(/g, '').replace(/\)/g, '')
    components.push(<a key={'message link piece' + count + text} href={safeHref} target='_blank' rel='noopener noreferrer'>{nextLink.linkText}</a>)
    messageText = messageText.slice(nextLink.locationIndex, messageText.length)
  }
  if (messageText.length) {
    components.push(<div key={'last message piece' + text} style={{display:'contents'}}>{messageText}</div>)
  }
  return (
    <div>
      {components.map(component => {
        return component
      })}
    </div>
  )
}

export const UnconnectedContent = props => {
  const {
    messageID,
    postedByThisUser,
    publishedUser,
    publishedMessage,
    hasPoll,
    isSubmission,
    posterUserType
  } = props
  let { text } = props
  const classes = useStyles()
  const links = []
  if (posterUserType === constants.MEMBER_TYPES.MODERATOR) {
    // parse out <Link to:https://example.com>Link Text</Link> into anchors
    while(text.indexOf('<Link to:') >= 0) {
      const locationIndex = text.indexOf('<Link to:')
      const location = text.substring(locationIndex + 9, text.indexOf('>', locationIndex))
      //remove <Link to:
      text = text.slice(0, locationIndex) + text.slice(text.indexOf('<Link to:') + 9 + location.length + 1)
      const linkText = text.substring(locationIndex, text.indexOf('</Link>'))
      //remove </Link>
      text = text.slice(0, locationIndex) + text.slice(locationIndex + linkText.length + 7)
      links.push({locationIndex, linkText, location})
    }
  }
  if (!publishedUser) {
    return (
      <div className={clsx(postedByThisUser && classes.reverse, classes.root)}>
        {hasPoll ? <Poll messageID={messageID}/> : null}
        <div style={hasPoll ? {float:'left', width:'100%'} : {}}>
          <MessageText text={text} links={links}/>
        </div>
        <Attachments messageID={messageID} isSubmission={isSubmission}/>
      </div>
    )
  } else {
    return (
      <div className={clsx(postedByThisUser && classes.reverse, classes.root)}>
        <div className={classes.published}>
          <div className={classes.theirMessageContainer}>
            <div className={classes.theirMessage}>
              <span style={{fontWeight:'bold', paddingRight:'0.4rem'}}>
                {publishedUser}
              </span>
              said
              <div style={{marginLeft: '2rem', textAlign:'start'}}>
                {publishedMessage}
              </div>
              <Divider variant='middle' className={classes.divider}/>
            </div>
          </div>
          <div style={{whiteSpace:'pre-wrap', overflowWrap: 'break-word'}}>
            {hasPoll ? <Poll messageID={messageID} isReply={true}/> : null}
            <div style={hasPoll ? {float:'left', width:'100%'} : {}}>
              <MessageText text={text} links={links}/>
            </div>
            <Attachments messageID={messageID} isSubmission={isSubmission}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { messageID, isSubmission } = ownProps
  let { publishedUser, publishedMessage } = ownProps
  const thisUser = selectors.getCurrentMember(state)
  let message
  if (isSubmission) {
    message = state.submission
    publishedUser = state.submission.published.name
  } else {
    message = selectors.getMessageByID(state, messageID)
    if (message.published) {
      publishedUser = message.published.name
      publishedMessage = message.published.content.message
    }
  }

  const { content, options } = message
  const text = message.content.message
  const postedByThisUser = thisUser && message.memberID === thisUser.userId
  const hasPoll = !!content.poll
  const posterUserType = isSubmission ? selectors.getCurrentMember.type : selectors.getMemberByID(state, message.memberID) && selectors.getMemberByID(state, message.memberID).type
  return { content, options, postedByThisUser, publishedMessage, publishedUser, text, messageID, hasPoll, isSubmission, posterUserType }
}

export default connect(mapStateToProps)(UnconnectedContent)
