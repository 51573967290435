/* eslint-disable no-undef */

import { EVENT_WEBSOCKET_MESSAGE } from '../store/chat/types'
import { Logger } from './utilities'

const EventWebsocket = params => new Promise((
  resolve, reject
) => {
  const websocket = new window.ScaleDrone(process.env.REACT_APP_SCALEDRONE)
  websocket.on('open', err => {
    if (err) {
      Logger.error('event websocket connection error:', err)
      return reject(err)
    }
    const { clientID, eventID } = params
    const room = websocket.subscribe(`${clientID}_${eventID}`)
    websocket.on('publish', x => {
      Logger.log('websocket published:', x)
    })
    room.on('publish', x => {
      Logger.log('websocket room published:', x)
    })
    const wssConnection = dispatch => {
      ['close', 'error', 'disconnect', 'reconnect', 'publish', 'message', 'any']
      .forEach(event => {
        room.on(event, data => {
          if (typeof data === 'string') {
            try {
              data = JSON.prase(data)
            } catch(e) {
              Logger.warn('Could not parse websocket message', e)
            }
          }
          dispatch({
            type: EVENT_WEBSOCKET_MESSAGE,
            event,
            data
          })
        })
      })
    }
    const wssrecieve = dispatch => {
      room.on('publish', message => {
        let data = message
        Logger.log('websocket room on:', message)
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data)
          } catch (e) {
            Logger.warn('could not parse room websocket mesage', e)
          }
        }
        dispatch({
          type: EVENT_WEBSOCKET_MESSAGE,
          event: 'roomMessage',
          data
        })
      })
    }

    room.on('open', roomError => {
      if (roomError) return reject(roomError)
      resolve({ wssrecieve, wssConnection })
    })
  })
  return websocket
})

export default EventWebsocket
