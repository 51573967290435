import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import selectors from '../../../store/selectors'
import constants from '../../../lib/constants'
import actions from '../../../lib/actions'

const useStyles = makeStyles(() => ({
  baseContainer: constants.STYLES.MESSAGE_CONTAINER_ROOT,
  editContainer: {
    marginTop: '1rem',
    height: '12rem',
  }
}))

export const UnconnectedEdit = props => {
  const {
    text,
    hasPoll,
    hasPublished,
    hasAttachments,
    message,
    closeEdit,
    editMessage
  } = props
  const [newText, setNewText] = useState(text)
  const classes = useStyles()

  return (
    <div className={clsx(classes.baseContainer, classes.editContainer)}>
      {hasPoll ? <Chip color='secondary' size='small' label="Graphs can't be edited" /> : null}
      {hasPublished ? <Chip color='secondary' size='small' label="Original published message can't be edited"/> : null}
      {hasAttachments ? <Chip color='secondary' size='small' label="Original attachments can't be edited"/> : null}
      <div>Original Message: {text}</div>
      <Divider variant='middle' />
      <TextField
        variant='outlined'
        value={newText}
        onChange={e => setNewText(e.target.value)}
        multiline
        fullWidth
      />
      <div style={{marginTop: '0.5rem'}}>
        <Button
          size='small'
          color='primary'
          variant='contained'
          onClick={() => {
            const newMessage = Object.assign({}, message)
            newMessage.content.message = newText
            editMessage(newMessage)
            closeEdit()
          }}
        >
          Save Changes
        </Button>
        <Button
          size='small'
          color='secondary'
          style={{marginLeft: '1rem'}}
          variant='contained'
          onClick={() => {
            closeEdit()
          }}
        >
          Cancel Edit
        </Button>
      </div>
    </div>
  )

}

const mapDispatchToProps = dispatch => {
  return {
    editMessage: message => {
      dispatch(actions.editMessage({message}))
    },
    closeEdit: () => {
      dispatch(actions.changeShowEdit(false))
    }
  }
}

const mapStateToProps = state => {
  const message = selectors.getMessageByID(state, state.messages.showEdit)
  const text = message.content.message
  const hasPoll = !!message.content.poll
  const hasPublished = !!message.content.published
  const hasAttachments = !!message.content.attachments
  return { text, hasPoll, hasPublished, hasAttachments, message }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedEdit)
