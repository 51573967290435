/* eslint-disable no-undef */
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'

import InnerSidebar from './inner-sidebar'
import Item from './item'
import Sidebar from './sidebar'
import constants from '../../lib/constants'
import selectors from '../../store/selectors'
import actions from '../../lib/actions'

const useStyles = makeStyles(() => {
  return {
    drawer: {
      maxWidth: '33%',
      width: props => props.width,
      overflow: 'hidden',
      border: 0
    },
    nestedDrawer: {
      maxWidth: '33%',
      width: props => props.innerWidth,
      marginLeft: props => props.width,
      zIndex: -1
    }
  }
})

const convertRemToPixels = rem => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

const MainDrawer = props => {
  const {
    userIsAdmin,
    showSidebar,
    showSubSidebar,
    selected,
    userMessageCount,
    newUserMessages,
    moderatorMessageCount,
    newModeratorMessages,
    changeSideTab,
    isLoaded
  } = props
  let width = constants.STYLES.DRAWER_WIDTH
  let innerWidth = constants.STYLES.INNER_DRAWER_WIDTH
  if (convertRemToPixels(parseInt(width, 10)) > window.innerWidth / 3) {
    width = '33%'
    innerWidth = '33%'
  }
  const classes = useStyles({width, innerWidth})
  if (!isLoaded || !showSidebar) return null
  return (
    <div>
      {
        userIsAdmin ?
        <Drawer
          variant='persistent'
          anchor='left'
          open={!!showSidebar}
          classes={{paper:classes.drawer}}
        >
          <Sidebar
            header=''
            type='main'
          >
            <Item
              selected={selected === constants.CHAT.GENERAL}
              tab={constants.CHAT.GENERAL}
              name='Visitors'
              notificationCount={userMessageCount}
              hasNew={newUserMessages}
              handleClick={() => {
                changeSideTab(constants.CHAT.GENERAL)
              }}
            />
            <Item
              selected={selected === constants.CHAT.MODERATOR}
              tab={constants.CHAT.MODERATOR}
              name='Moderators'
              notificationCount={moderatorMessageCount}
              hasNew={newModeratorMessages}
              handleClick={() => {
                changeSideTab(constants.CHAT.MODERATOR)
              }}
            />
            <Item
              selected={selected === constants.CHAT.POLL}
              tab={constants.CHAT.POLL}
              name='Polling'
              notificationCount={0}
              hasNew={false}
              handleClick={() => {
                changeSideTab(constants.CHAT.POLL)
              }}
            />
          </Sidebar>
          <Drawer
            variant='persistent'
            anchor='left'
            open={!!(showSidebar && showSubSidebar)}
            classes={{paper:clsx(classes.drawer, classes.nestedDrawer)}}
          >
            <InnerSidebar/>
          </Drawer>
        </Drawer> : null
    }
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    changeSideTab: mainTab => {
      dispatch(actions.changeMainTab({mainTab}))
    },
  }
}

const mapStateToProps = state => {
  const isLoaded = state.members.loaded
  const thisUser = selectors.getCurrentMember(state)
  const userIsAdmin = thisUser && thisUser.type === constants.MEMBER_TYPES.MODERATOR
  const showSidebar = state.sidebar.mainExpanded && thisUser
  const showSubSidebar = state.sidebar.subExpanded && thisUser
  const selected = selectors.getCurrentMainTab(state)
  const [userMessageCount, newUserMessages] = selectors.getMainTabMessageCountAndHasNew(state, constants.CHAT.GENERAL)
  const [moderatorMessageCount, newModeratorMessages] = selectors.getMainTabMessageCountAndHasNew(state, constants.CHAT.MODERATOR)
  // const newUserMessages = !!selectors.getUnseenRoomMessages(state, constants.CHAT.GENERAL).length
  // const newModeratorMessages = !!selectors.getUnseenRoomMessages(state, constants.CHAT.MODERATOR).length

  return { isLoaded, userIsAdmin, showSidebar, showSubSidebar, selected, userMessageCount, moderatorMessageCount, newUserMessages, newModeratorMessages }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainDrawer)
