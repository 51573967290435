export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE'
export const CHAT_ON_CLOSE = 'CHAT_ON_CLOSE'
export const CHAT_SENT_CLOSE = 'CHAT_SENT_CLOSE'
export const CHAT_RECIEVED_MESSAGE = 'CHAT_RECIEVED_MESSAGE'
export const CHAT_ON_CONNECTION = 'CHAT_ON_CONNECTION'
export const CHAT_ATTEMPT_CONNECTION = 'CHAT_ATTEMPT_CONNECTION'
export const CHAT_CONNECTED_MEMBERS = 'CHAT_CONNECTED_MEMBERS'
export const CHAT_MEMBERS_RESPONSE = 'CHAT_MEMBERS_RESPONSE'
export const CHAT_ON_ERROR = 'CHAT_ON_ERROR'
export const EVENT_REQUEST = 'EVENT_REQUEST'
export const GET_USER = 'GET_USER'
export const GET_USER_RESPONSE = 'GET_USER_RESPONSE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const RECONNECT_USERS_TO_CHAT = 'RECONNECT_USERS_TO_CHAT'
export const UPDATE_CHAT_SETTINGS = 'UPDATE_CHAT_SETTINGS'
export const CHAT_EVENT_DATA = 'CHAT_EVENT_DATA'
export const EVENT_WEBSOCKET_MESSAGE = 'EVENT_WEBSOCKET_MESSAGE'
export const HEART_BEAT = 'HEART_BEAT'
export const EVENT_CONNECT_REQUEST = 'EVENT_CONNECT_REQUEST'
export const EVENT_CONNECT_RESPONSE = 'EVENT_CONNECT_RESPONSE'
export const EVENT_CONNECT_ERROR = 'EVENT_CONNECT_ERROR'