import {
  CHANGE_ROOM,
  SET_USER_MUTE,
  SET_THIS_USER,
  GET_MEMBERS_REQUEST,
  GET_MEMBERS_RESPONSE,
  NEW_CHAT_USER,
  USER_UPDATE,
} from './types'
import { LOGIN_RESPONSE, LOGIN_REQUEST, GET_USER, GET_USER_RESPONSE, CHAT_MEMBERS_RESPONSE } from '../chat/types'
import constants from '../../lib/constants'

const getInitialState = () => ({
  thisUser: null,
  pending: false,
  loaded: false,
  data: {},
  error: false
})

const reducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case CHANGE_ROOM: {
      return Object.assign(
        {},
        state,
        //state.data
        { data: Object.assign({}, state.data,
          {
            // state.data[userID]
            [state.thisUser]: Object.assign(
              {},
              state.data[state.thisUser],
              {
                // state.data[userID].currentRoom/lastIn
                currentRoom: action.payload.room,
                lastIn: Object.assign(
                  {},
                  state.data[state.thisUser].lastIn,
                  {[state.data[state.thisUser].currentRoom]: new Date().getTime()},
                  {[action.payload.room]: new Date().getTime()}
                )
              }
            )
          }
        )}
      )
    }
    case NEW_CHAT_USER: {
      const clone = Object.assign({}, state)
      // Logger.log('user created:', action.payload)
      if (!action.payload.userId) {
        return state
      }
      if (clone.data[action.payload.userId]) {
        delete action.payload.userId_verified
      }
      clone.data[action.payload.userId] = {...clone.data[action.payload.userId], ...action.payload}
      clone.data[action.payload.userId].isOnline = true
      if (action.payload.userId === state.thisUser) {
        clone.pending = false
        clone.loaded = true
        clone.data[action.payload.userId].from = constants.FROM.DB
      }
      return Object.assign({}, clone)
    }
    case LOGIN_REQUEST: {
      const clone = Object.assign({}, state)
      // Logger.log('login request;', action.payload)
      clone.pending = true
      return Object.assign({}, clone)
    }
    case LOGIN_RESPONSE: {
      const clone = Object.assign({}, state)
      clone.pending = false
      // Logger.log('login response:', action.payload)
      if (action.payload === null) {
        // bad login
        clone.thisUser = null
      } else if(action.payload && action.payload.email) {
        const email = action.payload.email
        const userData = action.payload
        userData.id = email
        delete userData.email
        delete userData.password
        if (!userData.lastIn) {
          userData.lastIn = {}
        }
        // if (!userData.currentRoom) {
          userData.currentRoom = constants.CHAT.GENERAL
        // }
        clone.data[email] = (Object.assign({}, userData))
        clone.thisUser = email
      }
      return Object.assign({},clone)
    }
    case GET_MEMBERS_REQUEST: {
      const clone = Object.assign({}, state)
      clone.pending = true
      return Object.assign({}, clone)
    }
    case GET_MEMBERS_RESPONSE: {
      const clone = Object.assign({}, state)
      // gets all of the chat members data
      const members = action.payload
      Object.keys(members).forEach(key => {
        const member = members[key]
        if (!clone.data[member.userId]) {
          // user wasn't loaded yet load them entirely from here
          clone.data[member.userId] = member
        } else {
          // merge existing user with newly retrieved user except isOnline
          delete member.isOnline
          clone.data[member.userId] = {...clone.data[member.userId], ...member}
          if (clone.data[member.userId].isOnline === undefined) {
            clone.data[member.userId].isOnline = false
          }
        }
        clone.data[member.userId].from = constants.FROM.DB
      })
      // clone.pending = false
      // clone.loaded = true
      return Object.assign({}, clone)
    }
    case CHAT_MEMBERS_RESPONSE: {
      const clone = Object.assign({}, state)
      // get the currently live chat members
      var onlineMembers = {}
      action.payload.forEach(member => {
        if (member.userId) {
          onlineMembers[member.userId] = true
          if (!clone.data[member.userId]) {
            clone.data[member.userId] = {isOnline: true, userId: member.userId}
          }
        }
      })
      Object.keys(clone.data).forEach(memberKey => {
        if (onlineMembers[memberKey]) {
          clone.data[memberKey].isOnline = true
        } else {
          clone.data[memberKey].isOnline = false
        }
      })
      return Object.assign({}, clone)
    }
    case SET_USER_MUTE: {
      const clone = Object.assign({}, state)
      clone.data[action.payload.memberID].options.muted = new Date().getTime() + action.payload.muteOption
      return Object.assign({}, clone)
    }
    case SET_THIS_USER: {
      const clone = Object.assign({}, state)
      const user = action.payload.user
      const from = action.payload.from
      // set this user to be the userId if it exists otherwise use the email
      clone.thisUser = user.userId || user.email
      // if the user id was set remove the holder with the email key after merging it in
      if (user.userId) {
        clone.data[user.userId] = Object.assign({}, clone.data[user.email], user)
        delete clone.data[user.email]
      }
      user.from = from
      clone.data[clone.thisUser] = Object.assign({}, user, clone.data[clone.thisUser])
      clone.data[clone.thisUser].userId_verified = user.userId_verified
      if (clone.data[clone.thisUser].type !== constants.MEMBER_TYPES.MEMBER && clone.data[clone.thisUser].userId_verified === false) {
        // this userId is for a moderator but has not been verified so they cannot access moderator features yet
        clone.data[clone.thisUser].type = constants.MEMBER_TYPES.MEMBER
      }
      return Object.assign({}, clone)
    }
    case GET_USER_RESPONSE: {
      const clone = Object.assign({}, state)
      const email = action.payload.email
      let userData = {...action.payload.userData}
      delete action.payload.userData
      userData = { ...userData, ...action.payload}
      clone.data[email] = Object.assign({}, clone.data[email], userData)
      clone.data[email].from = constants.FROM.DB
      clone.pending = false
      return Object.assign({}, clone)
    }
    case USER_UPDATE: {
      const clone = Object.assign({}, state)
      const userId = action.payload.userId
      delete action.payload.userId
      delete action.payload.id
      const data = {...action.payload}
      data.userId = userId
      clone.data[userId] = {...clone.data[userId], ...data}
      clone.data[userId].from = constants.FROM.DB
      return Object.assign({}, clone)
    }
    case GET_USER: {
      const clone = Object.assign({}, state)
      clone.pending = true
      return Object.assign({}, clone)
    }
    // case CHAT_ON_CLOSE: {
    //   return getInitialState()
    // }
    default: {
      return state
    }
  }
}

export default reducer
