import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../lib/actions'
import { useDebouncedEffect } from '../../lib/utilities'

const useStyles = makeStyles(() => ({
  root: {
    height: '3rem',
    lineHeight: '3rem',
    color: '#717271',
    fontSize: '1.7rem',
    paddingLeft: '1rem',
    display: 'flex',
  },
  header: {
    maxWidth: (props) => (!props.searchFocus ? Math.min(props.text.length, 10) + 'ch' : '0ch'),
    overflow: 'hidden',
    transition: 'max-width 1s linear',
  },
  smallHeader: {
    maxWidth: 0,
    transition: 'max-width 0.7s linear',
  },
  searchText: {
    // search text size is between 5ch and 15 depending on the length of the search text
    maxWidth: (props) =>
      props.localSearch
        ? Math.max(5, Math.min(props.localSearch.length - 1, 15)) + 'ch'
        : '5ch',
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    right: 0,
    backgroundColor: 'white',
    transition: 'max-width 1s linear',
  },
  searchTextFocused: {
    maxWidth: (_props) => '15ch',
    transition: 'max-width 1s ease',
  },
}))

export const UnconnectedHeading = (props) => {
  const { text, type } = props
  const dispatch = useDispatch()
  const sidebarSearch = useSelector((state) => state.sidebar.search)
  const changeSearch = (search) => dispatch(actions.changeSearch({ search }))
  const [localSearch, setLocalSearch] = useState(sidebarSearch || '')
  const [searchFocus, setSearchFocus] = useState(false)
  useDebouncedEffect(() => {
    if (localSearch !== sidebarSearch) {
      changeSearch(localSearch)
    }
  }, 300, [localSearch])
  const classes = useStyles({ text, searchFocus, localSearch })
  return (
    <div className={classes.root}>
      <div className={clsx(classes.header, searchFocus && classes.smallHeader)}>{text}</div>{' '}
      {type === 'sub' && text !== 'Polls' ? (
        <TextField
          className={clsx(classes.searchText, searchFocus && classes.searchTextFocused)}
          variant='outlined'
          size='small'
          label='search'
          onFocus={(e) => {
            e.target.select()
            setSearchFocus(true)
          }}
          onBlur={() => setSearchFocus(false)}
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
        />
      ) : null}
    </div>
  )
}

export default UnconnectedHeading
