import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import Drawer from '@material-ui/core/Drawer'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import BarChartIcon from '@material-ui/icons/BarChart'
import clsx from 'clsx'

import Attachments from './attachments'
import constants from '../../../lib/constants'
import { Badge, Snackbar } from '@material-ui/core'
import actions from '../../../lib/actions'
import selectors from '../../../store/selectors'
import Login from '../../settings/login-button'
import { useDebouncedEffect, useTimeout } from '../../../lib/utilities'

const useStyles = makeStyles(() => {
  return {
    warningMessage: {
      backgroundColor:'red'
    },
    root: {
      paddingLeft: '0.3rem'
    },
    submissionContainer: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    drawer: {
      maxWidth: '80%'
    },
    textContainer: {
      minWidth: '30ch',
      flexGrow: 1,
      paddingRight: '3rem'
    },
    textField: {
      width: '100%',
      color: '#4d4d4d'
    },
    send: {
      margin: 'auto',
      marginRight: 0,
      padding: '0.3rem',
      paddingLeft: 0,
      paddingRight: '0.6rem',
      zIndex: 1
    },
    small: {
      height: 'auto',
      width: '5rem',
      marginTop: 0,
      marginLeft: '1rem'
    },
    icon: {
      height: '100%',
      width: '100%',
      backgroundColor: constants.COLORS.BLUE,
      borderRadius: '50%',
      padding: '12px',
      color: 'white'
    },
    iconButton: {
      padding: 0
    },
    iconButtonPending: {
      backgroundColor: 'gray',
      cursor: 'not-allowed'
    },
    textFieldInput: {
      fontSize: '21px'
    },
    badge: {
      backgroundColor: '#080',
      borderRadius: '50%',
      color: 'white',
      border: '1px solid white',
      width: '1.5rem',
      height: '1.7rem',
      marginRight: 0
    },
    invalid: {
      backgroundColor: '#F00'
    },
  }
})

const getMainButtonText = ({saveSubmission, selectedIndex, selectedMessage, isGroupChat, hasPoll, currentRoom, options}) => {
  if (saveSubmission) return 'Save For Later'
  if (isGroupChat) return hasPoll ? 'Create Poll' : `Message ${currentRoom}`
  if (selectedIndex === options.sendMessage) {
    return selectedMessage ? 'Send Direct Reply' : 'Send Direct Message'
  } else if(selectedIndex === options.publishReply) {
    return hasPoll ? 'Publish Response Poll' : 'Publish A Response'
  }
}

const Submit = props => {
  const {
    thisUser,
    selectedMessage,
    attachments,
    showPoll,
    sendMessage,
    setShowReply,
    publishReply,
    updateSubmissionText,
    addPublishedMessageToSubmission,
    removePublishedMessageFromSubmission,
    hasPoll,
    pending,
    isGroupChat,
    publishPending,
    userType,
    currentRoom,
    sideTab,
    updateSubmissionSaved,
    saveSubmission,
    submissionMessage,
    submissionError,
    submissionTimeout,
    clearSubmissionError
  } = props
  const [pendingTime, setPendingTime] = useState(null)
  const [text, setText] = useState('')
  const [shouldReset, setShouldReset] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(4)
  const [showAttachmentDrawer, setShowAttachmentDrawer] = useState(false)
  const [mainButtonText, setMainButtonText] = useState('Send a Direct Message')

  const optionText = ['Add Link', 'Polling', 'Attachments', 'Publish A Reply', 'Send Direct Message', 'Save For Later']
  const options = {
    addLink: 0,
    addPoll: 1,
    addAttachments: 2,
    publishReply: 3,
    sendMessage: 4,
    saveMessage: 5
  }

  // after some timeout period sent messages should show a message that the request may have timed out
  const MESSAGE_TIMEOUT_MS = 15000
  useEffect(() => {
    if (pending) {
      setPendingTime(new Date().getTime())
    } else {
      setPendingTime(null)
    }
  }, [pending])
  useDebouncedEffect(() => {
    if (pendingTime !== null && pending === true && pendingTime + MESSAGE_TIMEOUT_MS < new Date().getTime()) {
      submissionTimeout()
    }
  }, MESSAGE_TIMEOUT_MS, [pending, pendingTime])

  useLayoutEffect(() => {
    if (submissionMessage !== text) {
      setText(submissionMessage)
    }
  }, [submissionMessage])

  // can't publish a reply if there is no selected message
  useEffect(() => {
    if (!selectedMessage && selectedIndex === options.publishReply) {
      setSelectedIndex(options.sendMessage)
    }
  }, [sideTab, options.publishReply, options.sendMessage, selectedIndex, selectedMessage])

  useEffect(() => {
    if (pending && !shouldReset) {
      setShouldReset(true)
    }
    if (shouldReset && !pending && submissionError) {
      setShouldReset(false)
    }
    if (shouldReset && !pending && !submissionError) {
      setText('')
      setSelectedIndex(options.sendMessage)
      setShouldReset(false)
    }
  }, [pending, shouldReset, submissionError])

  const hasAttachments = !!attachments.length
  const handleClick = () => {
    if (selectedIndex === options.sendMessage || userType === constants.MEMBER_TYPES.MEMBER) {
      sendMessage()
    } else if (selectedIndex === options.publishReply) {
      publishReply()
    } else if (selectedIndex === options.saveMessage) {
      sendMessage() // won't actually be sent to users but stored in the database
    }
  }

  // handle main submit button text
  const newMainButtonText = getMainButtonText({
    saveSubmission,
    selectedIndex,
    selectedMessage,
    isGroupChat,
    hasPoll,
    currentRoom,
    options
  })
  if (mainButtonText !== newMainButtonText) {
    setMainButtonText(newMainButtonText)
  }

  const handleMenuItemClick = (event, index) => {
    setOpen(false)
    setSelectedIndex(index)
    switch (index) {
      case options.addLink:
        setText(prev => prev + '<Link to:https://example.com>Link Text</Link>')
        updateSubmissionText(text + '<Link to:https://example.com>Link Text</Link>')
        setSelectedIndex(selectedMessage ? options.publishReply : options.sendMessage)
        break
      case options.addPoll:
        setSelectedIndex(selectedMessage ? options.publishReply : options.sendMessage)
        if (selectedMessage) {
          addPublishedMessageToSubmission()
        }
        showPoll()
        break
      case options.addAttachments:
        setShowAttachmentDrawer(true)
        break
      case options.publishReply:
        setShowReply(true)
        addPublishedMessageToSubmission()
        break
      case options.sendMessage:
        setShowReply(false)
        removePublishedMessageFromSubmission()
        break
      case options.saveMessage:
        updateSubmissionSaved(!saveSubmission)
    }
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  useDebouncedEffect(() => {
    if (submissionMessage !== text) {
      updateSubmissionText(text)
    }
  }, 500, [text, submissionMessage])

  let isValidPoll = true
  let invalidMessage = false
  let attachmentNames = ''
  let pollToolTip = ''
  // if (currentRoom === constants.CHAT.POLL) {
  //   invalidMessage = true
  // }
  attachments.forEach(attachment => {
    attachmentNames += attachment.name + ', '
  })
  if (attachmentNames.endsWith(', ')) {
    attachmentNames = attachmentNames.slice(0, attachmentNames.length - 2)
  }
  if (selectedIndex === options.sendMessage && attachmentNames && !publishPending && currentRoom !== 'Public' && currentRoom !== 'Moderator') {
    attachmentNames = 'Attachments can\'t be sent in direct messages'
    invalidMessage = true
  }
  if (!isGroupChat && hasPoll && selectedIndex !== options.publishReply && currentRoom !== 'Public' && currentRoom !== 'Moderator') {
    pollToolTip = 'Polls can\'t be sent in direct messages'
    invalidMessage = true
    isValidPoll = false
  }
  if (text === '') {
    invalidMessage = true
  }
  const classes = useStyles({buttonWidth: userType === constants.MEMBER_TYPES.MODERATOR ? 15 : 5})
  if(!thisUser) {
    return (
      <Paper className={classes.notLoggedIn}>
        <Login isTextButton={true} noPadding={true}/> to join the discussion
      </Paper>
    )
  }
  return (
    <div className={classes.root}>
      <Drawer
        classes={{paper:classes.drawer}}
        anchor='right'
        open={showAttachmentDrawer}
        onClose={() => {
          setShowAttachmentDrawer(false)
          setSelectedIndex(selectedMessage ? options.publishReply : options.sendMessage)
        }}
      >
        {selectedIndex === options.addAttachments ?
          <Attachments
            close={() => {
              setShowAttachmentDrawer(false)
              setSelectedIndex(selectedMessage ? options.publishReply : options.sendMessage)
            }}
          /> :
          null
        }
      </Drawer>
      <Snackbar
        onClose={() => clearSubmissionError()}
        anchorOrigin={{vertical:'bottom', horizontal: 'center'}}
        open={!!submissionError}
        ContentProps={{classes:{root: classes.warningMessage}}}
        message={submissionError}
        autoHideDuration={7000}
      />
      <div className={classes.submissionContainer}>
        <div className={classes.textContainer}>
          <TextField
            label='Chat'
            multiline
            className={classes.textField}
            InputProps={{classes: {input: classes.textFieldInput}}}
            margin='normal'
            rowsMax='3'
            value={text}
            onChange={e => {
              setText(e.target.value)
            }}
            onBlur={e => {
              updateSubmissionText(e.target.value)
            }}
          />
        </div>
        {
          userType === constants.MEMBER_TYPES.MODERATOR ?
          <div className={classes.send}>
            <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent={
              hasPoll ?
              <Tooltip title={pollToolTip}>
                <IconButton
                  style={{padding:'0'}}
                  onClick={() => {
                    showPoll()
                    setSelectedIndex(selectedMessage ? options.publishReply : options.sendMessage)
                  }}
                >
                  <BarChartIcon
                    className={
                      clsx(classes.badge,
                      isValidPoll ?
                      classes.validPoll                      :
                      classes.invalid)
                    }
                  />
                </IconButton>
              </Tooltip>              :
              null
            }
          >
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent={
                hasAttachments ?
                <Tooltip title={hasAttachments ? attachmentNames : ''}>
                  <IconButton
                    style={{padding:'0'}}
                    onClick={() => {
                      setSelectedIndex(options.addAttachments)
                      setShowAttachmentDrawer(true)
                    }}
                  >
                    <AttachFileIcon className={clsx(classes.badge, selectedIndex === options.sendMessage && !publishPending && currentRoom !== 'Public' && currentRoom !== 'Moderator' && classes.invalid)} />
                  </IconButton>
                </Tooltip>                :
                null
              }
            >
              <Tooltip enterDelay={500} title={invalidMessage && text === '' ? 'Message must not be empty' : ''}>
                <ButtonGroup
                  disabled={pending}
                  ref={anchorRef}
                  variant='contained'
                  color='primary'
                  aria-label='split button'
                >
                  <Button disabled={pending || invalidMessage} onClick={handleClick}>{pending ? 'Sending Message' : mainButtonText}</Button>
                  <Button
                    onClick={handleToggle}
                    color='primary'
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label='select submit option'
                  >
                    <ArrowDropUpIcon />
                  </Button>
                </ButtonGroup>
              </Tooltip>
            </Badge>
          </Badge>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      {optionText.map((option, index) => (
                        <Tooltip key={option + 'tooltip'} title={!selectedMessage && index === options.publishReply ? 'Select a Message' : ''} placement='left'>
                          <div>
                            <MenuItem
                              key={option}
                              disabled={!selectedMessage && index === options.publishReply}
                              selected={index === selectedIndex}
                              onClick={event => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          </div>
                        </Tooltip>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>        : null
        // <div className={clsx(classes.send, classes.small)}>
        //   <IconButton className={classes.iconButton} onClick={handleClick}>
        //     <ArrowForwardIcon className={clsx(classes.icon, pending && classes.iconButtonPending)} fontSize='small'/>
        //   </IconButton>
        // </div>
      }
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    submissionTimeout: () => {
      dispatch(actions.submissionTimeout({}))
    },
    showPoll: () => {
      dispatch(actions.changeSubmissionShowPoll({showPoll: true}))
    },
    setShowReply: toShow => {
      dispatch(actions.changeSubmissionShowReply({showReply: toShow}))
    },
    sendMessage: () => {
      dispatch(actions.startSubmitMessage())
    },
    updateSubmissionSaved: (saved) => {
      dispatch(actions.changeSubmissionSaved({ saved }))
    },
    publishReply: () => {
      dispatch(actions.startPublishReply())
    },
    updateSubmissionText: text => {
      dispatch(actions.changeSubmissionText({text}))
    },
    addPublishedMessageToSubmission: () => {
      dispatch(actions.addPublishedMessageToSubmission())
    },
    removePublishedMessageFromSubmission: () => {
      dispatch(actions.removePublishedMessageFromSubmission())
    },
    clearSubmissionError: () => {
      dispatch(actions.clearSubmissionError())
    }
  }
}

const mapStateToProps = state => {
  const selectedMessage = state.messages.selectedMessage || null
  const sideTab = state.sidebar.subTab
  const submissionMessage = state.submission.content.message
  const attachments = state.submission.content.attachments || []
  const poll = state.submission.content.poll
  const hasPoll = !!poll
  const pending = state.submission.pending
  const currentRoom = selectors.getCurrentRoom(state)
  let isGroupChat = false
  if (Object.keys(constants.CHAT).map(key => constants.CHAT[key]).includes(currentRoom)) {
    isGroupChat = true
  }
  const publishPending = !!state.submission.published
  const thisUser = selectors.getCurrentMember(state)
  const userType = thisUser ? thisUser.type : constants.MEMBER_TYPES.MEMBER
  const saveSubmission = state.submission.options.saved
  const submissionError = state.submission.error
  return {submissionError, selectedMessage, sideTab, attachments, submissionMessage, hasPoll, pending, isGroupChat, publishPending, thisUser, userType, currentRoom, saveSubmission}
}

export default connect(mapStateToProps, mapDispatchToProps)(Submit)
