import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import Chat from '../chat'
import Settings from '../settings/index'
import constants from '../../lib/constants'

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100% - 7rem)'
  }
}))

const Content = props => {
  const { content, chatPending } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {content === constants.CONTENT.MESSAGES && !chatPending ? <Chat /> : null}
      {content === constants.CONTENT.SETTINGS ? <Settings /> : null}
    </div>
  )
}

const mapStateToProps = state => {
  const content = state.content.content
  const chatPending = state.chat.settings.pending
  return { content, chatPending }
}

export default connect(mapStateToProps)(Content)
