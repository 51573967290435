/*global window */
import queryString from 'qs'
import { useCallback, useEffect } from 'react'

export const useDebouncedEffect = (effect, delay , deps) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
      const handler = setTimeout(() => {
          callback();
      }, delay);

      return () => {
          clearTimeout(handler);
      };
  }, [callback, delay]);
}

export const getRandomColor = () => {
  const max = 0xffffff
  return '#' + Math.round( Math.random() * max ).toString( 16 )
}

export const getTimeFormat = time => {
  if (typeof time !== 'number') {
    Logger.error('tried to get time format of not a number:', time)
  }
  if (time < 0 || time > 8640000000000000) {
    Logger.error('Tried to get time out of range:', time)
  }
  const date = new Date(time)
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  // the hour '0' should be '12'
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  const theDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear().toString().substring(2,4)
  return {date:theDate, time:strTime}
}

export const isDevEnv = (() => {
  const hasDebugQS = queryString.parse(
    window.location.search, { ignoreQueryPrefix: true }
  ).debug
  return process.env.NODE_ENV === 'development' ||
    hasDebugQS ||
    window.location.hostname.indexOf('localhost') >= 0
})()

export const getRestEndpoint = () => {
  if (isDevEnv) {
    return 'https://' + process.env.REACT_APP_TEST_REST_DOMAIN + '.execute-api.' + process.env.REACT_APP_REGION + '.amazonaws.com/Test/Chatauth/'
  } else {
    return 'https://' + process.env.REACT_APP_PROD_REST_DOMAIN + '.execute-api.' + process.env.REACT_APP_REGION + '.amazonaws.com/Prod/Chatauth/'
  }
}

const logTypes = [
  'error',
  'info',
  'log',
  'trace',
  'warn'
]

const getLogger = () => {
  const logger = {}
  logTypes.forEach(type => {
    logger[type] = (...args) => {
      if (isDevEnv) {
        // eslint-disable-next-line no-console
        console[type]('%c invintus.%s', 'font-weight: bold;',
        type,
        ...args
        )
      }
    }
  })
  return logger
}

export const Logger = getLogger()
