import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import AddCircle from '@material-ui/icons/AddCircle'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Hue from 'react-color/lib/components/hue/Hue'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import PaletteIcon from '@material-ui/icons/Palette'
import Popper from '@material-ui/core/Popper'

import Poll from './index'
import actions from '../../../lib/actions'
import constants from '../../../lib/constants'
import { getRandomColor } from '../../../lib/utilities'
import { Grow, ClickAwayListener } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #b0b0b9',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    backgroundColor: '#fbfbff',
    height: '100%',
    overflow:'auto'
  },
  label: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 'large',
    marginRight: '1rem'
  },
  options: {
    marginBottom: '1rem'
  },
  option: {
    '& *:focus': {
      backgroundColor: '#0002',
      borderRadius: '4px'
    },
    paddingLeft: '0.5rem',
    marginLeft: '0.5rem',
    marginTop: '0.5rem'
  },
  divider: {
    marginBottom: '1rem',
    width: '100%'
  },
  colorContainer: {
    '& *': {
      height: '100%'
    }
  },
  setting: {
    display: 'flex',
    alignItems: 'center'
  },
  optionText: {
    flex: 1,
    paddingLeft: '0.5rem',
    marginLeft:'-0.2rem',
    width: 'calc(100% - 3rem)'
  },
}))

const PollOption = props => {
  const {type, poll, updatePoll, index} = props
  const classes = useStyles()
  return (
    <Paper className={classes.option} style={{
      backgroundColor: poll.colors[index] + '80',
      minWidth: '8rem'
    }}>
      <InputBase
        className={classes.optionText}
        classes={{
          input: classes.optionText
        }}
        placeholder='Option'
        inputProps={{ 'aria-label': 'option'}}
        value={type}
        onChange={e => {
          const clone = Object.assign({}, poll)
          clone.types[index] = e.target.value
          updatePoll(clone)
        }}
      />
      <ColorPickerButton poll={poll} updatePoll={updatePoll} index={index}/>
    </Paper>
  )
}

const ColorPickerButton = props => {
  const { poll, updatePoll, index } = props
  const [isOpen, setOpen] = useState(false)
  const ref = useRef(null)
  const color = poll.colors[index]

  const handleColorChange = color => {
    const clone = Object.assign({}, poll)
    clone.colors[index] = color.hex
    updatePoll(clone)
  }

  return (
    <div style={{display:'inline'}}>
      <IconButton
        tabIndex='-1'
        ref={ref}
        aria-label='color picker'
        style={{backgroundColor: 'transparent', fontSize: '2rem'}}
        onClick={() => {
          setOpen(true)
        }}
      >
        <PaletteIcon />
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={ref.current}
        role={undefined}
        placement='bottom'
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top right'}}
          >
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper style={{margin:'-0.2rem 37% 0rem -37%', padding:'0.2rem 1rem 1rem 1rem'}}>
                Color
                <Hue
                  color={color}
                  RemoveCircle={true}
                  width='10rem'
                  height='1rem'
                  onChange={handleColorChange}
                />
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const CreatePoll = props => {
  const {poll, updatePoll, closePoll, resetPoll} = props
  if (!poll.types) {
    poll.types = ['','']
  }
  if (poll.label === undefined) {
    poll.label = 'label'
  }
  while (poll.types.length < 2) {
    poll.types.push('')
  }
  if (poll.colors === undefined) {
    poll.colors = poll.types.map(getRandomColor)
  }
  while (poll.colors.length < poll.types.length) {
    poll.colors.push(getRandomColor())
  }
  const graphTypes = ['Horizontal', 'Vertical']
  const classes = useStyles()

  const handleTypeChange = e => {
    const clone = Object.assign({}, poll)
    clone.graphType = e.target.value
    updatePoll(clone)
  }

  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
          <div style={{display:'flex', marginBottom:'1rem'}}>
            <div className={classes.label}>Graph Type</div>
            <Select
              native
              value={poll && poll.graphType || 'Vertical'}
              onChange={handleTypeChange}
            >
              {graphTypes.map(type => {
                return <option key={'graphType:' + type} value={type}>{type}</option>
              })}
            </Select>
          </div>
          <div>
            <Button
              style={{marginRight: '8rem'}}
              variant='contained'
              onClick={() => {
                const clone = Object.assign({}, poll)
                clone.colors = clone.colors.map(getRandomColor)
                updatePoll(clone)
              }}
            >
              Randomize Colors
            </Button>
            <Button
              style={{marginRight: '4rem'}}
              variant='contained'
              onClick={() => {
                resetPoll()
              }}
            >
              Remove
            </Button>
            <Button
              variant='contained'
              onClick={() => closePoll()}
            >
              Close
            </Button>
          </div>
        </div>
        <Grid container item xs={12} className={classes.options}>
          <Grid item xs={12} sm={4} className={classes.setting}>
            <div className={classes.label}>Label</div>
            <TextField
              value={poll.label}
              onChange={e => {
                const clone = Object.assign({}, poll)
                clone.label = e.target.value
                updatePoll(clone)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.setting}>
            <div className={classes.label}>Height</div>
            <TextField
              value={poll.height === undefined ? constants.STYLES.DEFAULT_POLL_HEIGHT : poll.height}
              tabIndex='0'
              onChange={e => {
                const clone = Object.assign({}, poll)
                clone.height = e.target.value
                updatePoll(clone)
              }}
              onKeyDown={e => {
                const clone = Object.assign({}, poll)
                if (clone.height === undefined || clone.height < 0) {
                  clone.height = constants.STYLES.DEFAULT_POLL_HEIGHT
                }
                if (e.key === 'ArrowUp') {
                  clone.height = clone.height + 1
                  updatePoll(clone)
                } else if (e.key === 'ArrowDown') {
                  clone.height = clone.height - 1
                  updatePoll(clone)
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <div className={classes.label}>Options</div>
          <IconButton
          disabled={poll.types.length > 19}
          onClick={() => {
            const clone = Object.assign({}, poll)
            clone.types.push('')
            updatePoll(clone)
          }}>
            <AddCircle />
          </IconButton>
          <IconButton
          disabled={poll.types.length < 3}
          onClick={() => {
            const clone = Object.assign({}, poll)
            clone.types.pop()
            updatePoll(clone)
          }}>
            <RemoveCircle />
          </IconButton>
        </Grid>
        <Grid container item xs={12} className={classes.options}>
          {poll.types.map((type,i) => <PollOption key={'option ' + i} type={type} poll={poll} updatePoll={updatePoll} index={i} />)}
        </Grid>
        <Grid container item xs={12} className={classes.options}>
          <Divider className={classes.divider}/>
          <div className={classes.label}>Preview</div>
          <Poll isSubmission />
        </Grid>
      </Grid>
    </div>
  )

}

const mapDispatchToProps = dispatch => {
  return {
    updatePoll: poll => {
      dispatch(actions.changeSubmissionPoll({poll}))
    },
    closePoll: () => {
      dispatch(actions.changeSubmissionShowPoll({showPoll: false}))
    },
    resetPoll: () => {
      dispatch(actions.resetPoll())
    }
  }
}

const mapStateToProps = state => {
  const poll = state.submission.content.poll || {}
  return {poll}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePoll)
