import {
  CHAT_ATTEMPT_CONNECTION,
  CHAT_ON_CONNECTION,
  CHAT_RECIEVED_MESSAGE,
  CHAT_ON_CLOSE,
  CHAT_EVENT_DATA,
  EVENT_WEBSOCKET_MESSAGE,
  EVENT_CONNECT_REQUEST,
  EVENT_CONNECT_RESPONSE,
  EVENT_CONNECT_ERROR
} from './types'
import { Logger } from '../../lib/utilities'

const getInitialState = () => (
  {
    eventData: {connected:false},
    pending: false,
    error: false,
    websocket: null,
    settings: {
      pending: true
    }
  }
)

const reducer = (state = getInitialState(), action) => {
  const clone = Object.assign({}, state)
  if (!state.websocket && !(state.pending || state.settings.pending)) {
    clone.error = { Error: 'No websocket connection' }
    if (
      action.type !== CHAT_ATTEMPT_CONNECTION &&
      action.type !== CHAT_ON_CONNECTION &&
      action.type !== EVENT_CONNECT_REQUEST &&
      action.type !== EVENT_CONNECT_RESPONSE &&
      action.type !== EVENT_CONNECT_ERROR
    ) {
      return clone
    }
  }

  switch (action.type) {
    case CHAT_ATTEMPT_CONNECTION: {
      clone.pending = true
      return Object.assign({}, clone)
    }
    case CHAT_ON_CONNECTION: {
      // Logger.log('connected to websocket:',action.webSocket)
      clone.pending = false
      clone.error = false
      clone.websocket = action.webSocket
      return Object.assign({}, clone)
    }
    case CHAT_RECIEVED_MESSAGE: {
      if (!action.payload.data) {
        // probably some internal server error
        // Logger.log('recieved message with no data', action.payload)
        clone.error = true
        return Object.assign({}, clone)
      }
      return Object.assign({}, clone)
    }
    case CHAT_ON_CLOSE: {
      // return Object.assign({}, getInitialState(), {eventData: {eventID: clone.eventData.eventID}})
      return {
        ...state,
        connected: false
      }
    }
    case CHAT_EVENT_DATA: {
      // in db the unique client_event id is used as the eventID primary key
      // so they should be separated when retrieved
      clone.eventData.combinedID = action.payload.eventData.eventID
      delete action.payload.eventData.eventID
      // clone.eventData = Object.assign({}, clone.eventData, action.payload.eventData)
      clone.eventData = Object.assign({}, clone.eventData, action.payload.eventData)
      clone.eventData.chat = Object.assign({}, clone.eventData.chat, action.payload.settings)
      clone.eventData.chat.chatTypeID = 2
      clone.settings.pending = false
      return clone
    }
    case EVENT_WEBSOCKET_MESSAGE: {
      if (action.data.data && typeof action.data.data === 'string') {
        let message
        try {
          message = JSON.parse(action.data.data)
        } catch(e) {
          Logger.error('message could not be parsed:', message)
          return state
        }
        if (message) {
          if (
            message.action.method === 'events' &&
            message.action.type === 'update' &&
            message.data &&
            message.data.eventID &&
            message.data.eventID === state.eventData.eventID &&
            message.data.clientID &&
            message.data.clientID === state.eventData.clientID
          ) {
            // this chat's event data was updated, set the new event data
            clone.eventData.title = message.data.title
            clone.eventData.eventStatus = message.data.eventStatus
            clone.eventData.chat = Object.assign({}, clone.eventData.chat, message.data.chat)
            return clone
          }
        } else {
          return state
        }
      }
      break
    }
    case EVENT_CONNECT_REQUEST: {
      clone.pending = true
      return clone
    }
    case EVENT_CONNECT_RESPONSE: {
      clone.pending = false
      clone.connected = true
      if (!clone.connected) {
        clone.error = action.response.errors
        return clone
      } else {
        const eventData = action.response
        clone.eventData = Object.assign({}, clone.eventData, eventData)
      }
      return clone
    }
    case EVENT_CONNECT_ERROR: {
      clone.pending = false
      clone.error = {Error: action.response.error}
      return clone
    }
  }
  return state
}

export default reducer
