import React from 'react'

import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

const NEW_MESSAGE_COLOR = '#20b21d'
const NO_NEW_MESSAGES_COLOR = '#8b8c8b'

const StyledBadge = withStyles(() => ({
  badge: {
    border: '1px solid white',
    backgroundColor: props => props.backgroundcolor,
    height: '2rem',
    width: '2rem',
    color: 'white',
    borderRadius: '100%',
    marginRight: '0.5rem',
  }
}))(Badge)

export const Notification = props => {
  const { count, hasNew } = props
  if (!count) return null
  return <StyledBadge badgeContent={count} max={999} backgroundcolor={hasNew ? NEW_MESSAGE_COLOR : NO_NEW_MESSAGES_COLOR}/>
}

export const OverlappingNotification = props => {
  const { count, children, hasNew } = props
  if (!count) return children
  return (
    <StyledBadge
      overlap='circle'
      anchorOrigin={{vertical:'bottom', horizontal:'right'}}
      badgeContent={count}
      max={999}
      backgroundcolor={hasNew ? NEW_MESSAGE_COLOR : NO_NEW_MESSAGES_COLOR}
    >
      {children}
    </StyledBadge>
  )
}
