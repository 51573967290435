import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import smoothscroll from 'smoothscroll-polyfill'
/* eslint-disable no-undef */
import qs from 'querystring'
import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'

import { Auth0Provider } from './auth/react-auth0-wrapper'
import App from './App'
import Store from './store'
import { Logger, getRestEndpoint } from './lib/utilities'
// import config from './auth/auth_config.json'

smoothscroll.polyfill()
let email_auto_verified = {verified:false}

/**
 * 
 * @param {Object} data
 * data has keys: type, email, emailSecret, eventID, clientID, userID 
 */
const validateEmail = data => {
  let xhr = new XMLHttpRequest()
  xhr.open('POST', getRestEndpoint() + 'validateEmailSecret')
  xhr.send(JSON.stringify({data}))
  xhr.onload = () => {
    if (xhr.status === 200){
      email_auto_verified.verified = JSON.parse(xhr.responseText).validated
    } else {
      // Logger.log("on validate secret response non 200:", xhr)
    }
  }
  xhr.onerror = () => {
    Logger.warn('xhr on error:', xhr)
  }
}

// A function that routes the user to the right place
// after login to auth0
const onRedirectCallback = appState => {
  const {user, opts} = appState
  // if fromEmailLink call lambda to verify that the secret is correct for this email
  // if it is correct send an api call to verify the email in auth0
  if (opts.fromEmailLink && opts.email && opts.emailSecret) {
    validateEmail({
      type: 'validateEmailSecret',
      email: opts.email.toLowerCase(),
      emailSecret: opts.emailSecret,
      eventID: opts.eventID,
      clientID: opts.clientID,
      userID: user.sub
    })
  }
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  )
}

const EventChat = (() => ({
  // eslint-disable-next-line no-unused-vars
  launch: (opts = {}) => {
    const clientID = opts.clientID
    const eventID = opts.eventID
    let missingRequiredOpts = false
    if (!clientID || !eventID) {
      missingRequiredOpts = true
    }
    const fromEmailLink = opts.fromEmailLink
    const mountPoint = document.getElementById('root')

    if (mountPoint) {
      const mount = store => {
        ReactDOM.render(
          missingRequiredOpts ? <div>Could not connect to the chat provided for client: {clientID}, event: {eventID}<br/> Try Opening the link you recieved in your email.</div>:
          <Provider store={store}>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH_DOMAIN}
              client_id={process.env.REACT_APP_AUTH_CLIENT}
              redirect_uri={String(window.location.href)}
              onRedirectCallback={onRedirectCallback}
              opts={opts}
              origin={window.location.origin}
            >
              <App
                eventID={eventID}
                clientID={clientID}
                fromEmailLink={fromEmailLink}
                verifiedEmail={email_auto_verified}
              />
            </Auth0Provider>
          </Provider>, mountPoint
        )
      }

      Store({clientID, eventID})
      .then(store => {
        mount(store)
      // eslint-disable-next-line no-unused-vars
      }).catch(_err => {
        ReactDOM.render(
          <div>There was a problem connecting to the chat.</div>, mountPoint
        )
      })
    }
    if ((document.readyState === 'complete' || document.readyState === 'interactive') && document.getElementById('root').children.length === 0) {
      ReactDOM.render(
        <div>There was a problem connecting to the chat.</div>,document.getElementById('root')
      )
    }
    return
    }
  })
)

const queryStringParams = qs.decode(window.location.search.substr(1))
const {eventID, clientID, email, emailSecret} = queryStringParams
const fromEmailLink = !!queryStringParams.fromEmailLink
EventChat().launch({eventID, clientID, email, emailSecret, fromEmailLink, email_auto_verified})