/* eslint-disable no-undef */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import Content from './components/content'
import Loading from './components/loading'
import Sidebar from './components/sidebar'
import Header from './components/header'
import actions from './lib/actions'
import Login from './components/login'
import selectors from './store/selectors'
import { useAuth0 } from './auth/react-auth0-wrapper'
import constants from './lib/constants'
// import Popup from './components/popup'
import ErrorView from './components/error-view'

const App = (props) => {
  const {
    userType,
    connectToChat,
    thisUser,
    pending,
    websocket,
    usersPending,
    usersLoaded,
    eventID,
    clientID,
    fromEmailLink,
    eventPending,
    eventConnected,
    connectToEvent,
    mainExpanded,
    subExpanded,
    getUser,
    verifiedEmail,
    setUserEmailToVerified,
    eventConnectError,
  } = props

  // const classes = {}
  const [sentGet, setSentGet] = useState(false)
  const [gotMessages, setGotMessages] = useState(null)

  // if (thisUser.email_verified === false && verifiedEmail === true) {
  //   setUserEmailToVerified(thisUser)
  // }

  if (thisUser && thisUser.email && !thisUser.email_verified && verifiedEmail) {
    setUserEmailToVerified(thisUser)
    getUser({ userData: thisUser })
  }

  let margin = props.margin
  const marginInt = parseInt(margin, 10)
  const pageWidth = window.innerWidth
  if (convertRemToPixels(marginInt) > pageWidth / 3 && mainExpanded && !subExpanded) {
    margin = '33%'
  }
  if (convertRemToPixels(marginInt) > (pageWidth / 3) * 2 && subExpanded) {
    margin = '66%'
  }

  let { loading, isAuthenticated } = useAuth0()

  if (!eventConnected && !eventPending) {
    connectToEvent({ clientID, eventID })
    return <Loading />
  }

  if (loading || eventPending) return <Loading />
  // connect to event data

  if (fromEmailLink && !isAuthenticated) {
    // force login for email links to moderate chat
    return <Login fromEmailLink={fromEmailLink} />
  }
  // connect websocket to chat
  if (!pending && !websocket) {
    connectToChat(clientID + '_' + eventID)
  } else {
    if (websocket && !usersPending && !usersLoaded && !sentGet) {
      setSentGet(true)
    }
    if (!gotMessages) {
      if (thisUser) {
        getUser({ userData: thisUser })
      }
      setGotMessages(true)
    }
  }

  if (isAuthenticated && !thisUser) {
    return <Login />
  }
  // return isAuthenticated ? <MainContentContainer margin={margin}/> : <Login />
  let showUnverified = false
  // thisUser.type = "Moderator"
  if (!usersPending && usersLoaded && thisUser && !thisUser.email_verified) {
    showUnverified = true
  }
  let showNotModerator = false
  if (!usersPending && usersLoaded && userType !== constants.MEMBER_TYPES.MODERATOR) {
    showNotModerator = true
  }
  return (
    <>
      <MainContentContainer
        userIsLoggedIn={!!thisUser}
        usersLoaded={usersLoaded}
        usersPending={usersPending}
        margin={margin}
        showUnverified={showUnverified}
        showNotModerator={showNotModerator}
      />{' '}
      {eventConnectError ? (
        <ErrorView nonBlocking={true}>
          There was a problem connecting to the event: {eventConnectError.Error}
        </ErrorView>
      ) : null}
    </>
  )
}

const useStyle = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
  },
  content: {
    height: 'calc(100% - 56px)',
    flexGrow: 1,
    padding: '0 1rem',
    marginTop: '5rem',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: (props) => props.marginLeft || 0,
  },
  toolbar: theme.mixins.toolbar,
  fullHeight: {
    height: '100%',
  },
}))

const MainContentContainer = (props) => {
  let {
    userIsLoggedIn,
    margin,
    showUnverified,
    showNotModerator,
    usersLoaded,
    usersPending,
  } = props
  const classes = useStyle({ marginLeft: margin })
  return (
    <div className={classes.root}>
      {!usersLoaded || usersPending ? <Loading /> : null}
      <Header key={'header'} />
      {showNotModerator ? (
        <ErrorView key='not moderator error'>
          You do not have moderator control. Make sure you are logged in with your moderator email.
        </ErrorView>
      ) : null}
      {!showNotModerator && showUnverified ? (
        <ErrorView key='unverified error'>
          Your email account has not been verified, please check your email before continuing.
        </ErrorView>
      ) : null}
      {!showNotModerator && !showUnverified && !userIsLoggedIn ? (
        <ErrorView key='not loggedin error'>Login to join the discussion</ErrorView>
      ) : null}
      {!showNotModerator && !showUnverified && userIsLoggedIn ? (
        <>
          <Sidebar key={'sidebar'} />
          <main className={classes.content} key={'main content'}>
            <div className={clsx(classes.toolbar, classes.fullHeight)}>
              <Content />
            </div>
          </main>
        </>
      ) : null}
    </div>
  )
}
const mapStateToProps = (state, ownProps) => {
  const thisUser = selectors.getCurrentMember(state)
  const pending = state.chat.pending
  const usersPending = state.members.pending
  const usersLoaded = state.members.loaded
  const websocket = state.chat.websocket
  const eventPending = state.chat.pending
  const eventConnected = state.chat.connected
  const eventConnectError = state.chat.error
  let margin = 0
  if (state.sidebar.mainExpanded) {
    margin += parseInt(constants.STYLES.DRAWER_WIDTH, 10)
    if (state.sidebar.subExpanded) {
      margin += parseInt(constants.STYLES.INNER_DRAWER_WIDTH, 10)
    }
  }
  margin += 'rem'
  const mainExpanded = state.sidebar.mainExpanded
  const subExpanded = state.sidebar.subExpanded
  // args passed in to event-chat
  const verifiedEmail = ownProps.verifiedEmail.verified
  const { eventID, clientID } = ownProps
  let userType = 'User'
  if (thisUser && thisUser.type) {
    userType = thisUser.type
  }
  return {
    userType,
    thisUser,
    websocket,
    pending,
    usersPending,
    usersLoaded,
    eventID,
    clientID,
    eventPending,
    eventConnected,
    margin,
    mainExpanded,
    subExpanded,
    verifiedEmail,
    eventConnectError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    connectToEvent: ({ eventID, clientID }) => {
      dispatch(actions.connectToEvent({ eventID, clientID }))
    },
    connectToChat: (eventID) => {
      dispatch(actions.connectToChat({ eventID }))
    },
    getUser: (userData) => {
      dispatch(actions.getUser(userData))
    },
    setUserEmailToVerified: (user) => {
      user.email_verified = true
      dispatch(actions.setThisUser({ user }))
    },
  }
}

const convertRemToPixels = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
