import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Paper from '@material-ui/core/Paper'

import Login from '../settings/login-button'
import Header from './header/'
import Messages from './messages'
import Submit from './submit/'
import CreatePoll from './poll/create'
import CreateReply from './submit/reply'
import Edit from './messages/edit'
import selectors from '../../store/selectors'
import constants from '../../lib/constants'

const useStyles = makeStyles(() => {
  const headerHeight = 10
  const footerHeight = 11
  return {
    root: {
      height: '100%',
      width: '100%'
    },
    content: {
      // full - header - footer
      height: `calc(100vh - ${headerHeight}rem - ${footerHeight}vh)`,
    },
    padForReply: {
      paddingBottom: '13rem'
    },
    submitReplacement: {
      margin: '1rem',
      textAlign: 'center',
      padding: '1.5rem'
    }
  }
})

const Chat = props => {
  const classes = useStyles()
  const { showPoll, showReply, showEdit, thisUser, chatStarted, inModeratorView, standbyText } = props
  return (
    <div className={classes.root}>
      <Header />
      <div className={clsx(!showPoll && showReply && classes.padForReply, !showPoll && showEdit && classes.padForReply, classes.content)}>
        { showPoll ? <CreatePoll /> : <Messages />}
        { showReply && !showPoll ? <CreateReply /> : null}
        { showEdit && !showReply && !showPoll ? <Edit /> : null}
      </div>
      {
        !thisUser ?
          <Paper className={classes.submitReplacement}>
            <Login isTextButton={true} noPadding={true}/> to join the discussion
          </Paper> :
          (chatStarted || inModeratorView) ? <Submit /> : <Paper className={classes.submitReplacement}><div>{standbyText}</div></Paper>
      }
    </div>
  )
}

const mapStateToProps = state => {
  const { showReply, showPoll } = state.submission
  const { showEdit } = state.messages
  const standbyText = (state.chat.eventData && state.chat.eventData.chat.chatStandbyText || 'Please Standby for the discussion to begin') + ' -- You can start the chat in the top-right settings menu'
  const thisUser = selectors.getCurrentMember(state)
  const chatSettings = selectors.getChatSettings(state)
  const chatStarted = chatSettings.started
  const inModeratorView = selectors.getIsInModeratorView(state)
  return { showPoll, showReply, showEdit, thisUser, chatStarted, inModeratorView, standbyText }
}

export default connect(mapStateToProps)(Chat)
