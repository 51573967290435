import { combineReducers } from 'redux'

import chatReducer from './chat/reducers'
import membersReducer from './members/reducers'
import messagesReducer from './messages/reducers'
import sidebarReducer from './sidebar/reducers'
import contentReducer from './content/reducers'
import submissionReducer from './submission/reducers'

export default function createRootReducer() {
  return combineReducers({
    chat: chatReducer,
    members: membersReducer,
    messages: messagesReducer,
    sidebar: sidebarReducer,
    content: contentReducer,
    submission: submissionReducer,
  })
}