import React, { useState, useRef } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Grow from '@material-ui/core/Grow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  voteButtonContainer: {
    display: 'inline-flex'
  },
  label: {
    fontSize: 'large',
    alignSelf: 'center',
    fontWeight: 'bold',
    marginRight: '1rem'
  }
}))

const VoteButton = props => {
  const { types, onVote, canVote } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const classes = useStyles()
  if (!types || types.length === 0 || !canVote) {
    return null
  }
  const handleClick = () => {
    onVote(selectedIndex)
  }

  const handleMenuItemClick = (event, index) => {
    setOpen(false)
    setSelectedIndex(index)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  if (!types.length) {
    return null
  }
  return (
    <div className={classes.voteButtonContainer}>
      <div className={classes.label}>Vote </div>
      <ButtonGroup
        ref={anchorRef}
        variant='contained'
        color='primary'
        aria-label='split button'
      >
        <Button onClick={handleClick} style={{textTransform:'None'}}>{types[selectedIndex]}</Button>
        <Button
          onClick={handleToggle}
          color='primary'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select submit option'
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {types.map((type, index) => (
                    <MenuItem
                        key={type}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {type}
                      </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default VoteButton
