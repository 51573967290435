import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import EventWebsocketAdapter from '../lib/eventWebsocket'
import createRootReducer from './rootReducer'
import { Logger } from '../lib/utilities'

const rootReducer = createRootReducer()
const enhancer = applyMiddleware(
  thunk
)

export default function configureStore(parameters) {
  return EventWebsocketAdapter({ clientID: parameters.clientID, eventID: parameters.eventID})
    .then(({ wssrecieve, wssConnection }) => {
      const store = createStore(rootReducer, composeWithDevTools(enhancer))

      wssConnection(store.dispatch)
      wssrecieve(store.dispatch)
      return store
    }).catch(err => {
      Logger.warn('websocket did not connect:', err)
      return createStore(rootReducer, composeWithDevTools(enhancer))
    })
}