import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import mime from 'mime-types'
import uuid from 'uuid/v4'

import actions from '../../../lib/actions'

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem',
  },
  buttonHeader: {
    justifyContent: 'space-between'
  },
  marginLeft1: {
    marginLeft: '1rem'
  }
}))

export const UnconnectedAttachments = props => {
  const {
    attachments,
    changeSubmissionAttachments,
    close,
    getUploadUrl
  } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container item xs={12} className={classes.buttonHeader}>
        <Button
          variant='contained'
          component='label'
        >
          {attachments.length ? 'More Attachments' : 'Add Attachments'}
          <input
            type='file'
            style={{display: 'none'}}
            multiple
            onChange={e => {
              let newAttachments = [...attachments, ...e.target.files]
              const uniqueNames = {}
              newAttachments = newAttachments.filter(attachment => {
                if (uniqueNames[attachment.name]) {
                  return false
                }
                uniqueNames[attachment.name] = true
                return true
              })
              changeSubmissionAttachments(newAttachments)
            }}
          />
        </Button>
        <Button
          variant='contained'
          onClick={() => close()}
          className={classes.marginLeft1}
        >
          X
        </Button>
      </Grid>
      {attachments.map(attachment => {
        if (!attachment.url && !attachment.uploaded) {
          const contentType = mime.contentType(attachment.name)
          getUploadUrl(attachment.name, contentType)
        }
        return <div key={attachment.name}>
          {attachment.name}
          <IconButton
            onClick={() => changeSubmissionAttachments(attachments.filter(a => a.name !== attachment.name))}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      })}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    changeSubmissionAttachments: attachments => dispatch(actions.changeSubmissionAttachments({attachments})),
    getUploadUrl: (name, contentType) => dispatch(actions.getUploadUrl({name, contentType, assetId: uuid()})) 
  }
}

const mapStateToProps = state => {
  const attachments = state.submission.content.attachments || []
  return { attachments }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedAttachments)
