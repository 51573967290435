import React, { useState, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import MoreVert from '@material-ui/icons/MoreVert'
import { Grow, ClickAwayListener, IconButton, Popper, Paper, List, ListItem, Button } from '@material-ui/core'
import csvStringify from 'csv-stringify'

import LogoutButton from './logout-button'
import LoginButton from './login-button'
import constants from '../../lib/constants'
import StartChatButton from './start-chat-button'
import selectors from '../../store/selectors'
import PlayerPopper from '../player-popper'
import ChangeNameButton from './change-name'
import SaveChat, { download } from './save-chat'
import ExportUsers from './export-users'

const handleDownloadUserList = ({messages, users, combinedID, eventStartDate}) => {
  const data = Object.values(users).map(user => {
    const name = user.name
    let numMessages = 0
    Object.values(messages).forEach(message => {
      if (message.memberID === user.userId) {
        numMessages++
      }
    })
    return {
      'Name': name,
      'Type': user.type,
      'Email': user.email,
      'Messages Sent': numMessages
    }
  })
  csvStringify(
    data,
    {
      header: true,
      headers: ['Name', 'Type', 'Email', 'Messages sent']
    }, (err, output) => {
      download(output, 'Chat Users ' + combinedID + ' ' + eventStartDate + '.csv', 'text/csv')
    }
  )
}

const SettingsButton = props => {
  const { isLoggedIn, userType } = props
  const ref = useRef(null)

  const combinedID = useSelector(state => state.chat.eventData.combinedID)
  const eventStartDate = useSelector(state => state.chat.eventData.startDateTime)
  const messages = useSelector(state => state.messages.data)
  const users = useSelector(state => state.members.data)

  const [isOpen, setOpen] = useState(false)
  const [isPlayerOpen, setPlayerOpen] = useState(false)
  const [openLock, setOpenLock] = useState(false)
  const [showSaveChat, setShowSaveChat] = useState(false)
  const [showExportUsers, setShowExportUsers] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => {
        setOpen(false)
    }}>
    <div style={{ position: 'absolute', right: '0.5rem', top: '0.2rem' }}>
      <IconButton ref={ref} aria-label='Settings Button' style={{ color: 'white' }} onClick={() => setOpen(true)}>
        <MoreVert style={{ fontSize: '2rem' }} />
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={ref.current}
        role={undefined}
        placement='bottom'
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top right'
            }}
          >
            <Paper>
                <List>
                  <ListItem>
                    {isLoggedIn ? <LogoutButton /> : <LoginButton />}
                  </ListItem>
                  <ListItem>
                    {isLoggedIn && userType === constants.MEMBER_TYPES.MODERATOR ? <StartChatButton setOpenLock={setOpenLock} closeMenu={() => setOpen(false)} /> : null}
                  </ListItem>
                  <ListItem>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setPlayerOpen(prev => !prev)
                        setOpen(false)
                      }}
                    >
                      {isPlayerOpen ? 'Hide Player' : 'Show Player'}
                    </Button>
                  </ListItem>
                  {/* <ListItem>
                    {isLoggedIn && userType === constants.MEMBER_TYPES.MODERATOR ? <ChangeNameButton setOpenLock={setOpenLock} /> : null}
                  </ListItem> */}
                  <ListItem>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setShowSaveChat(true)
                      }}
                    >
                      Export Messages
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        handleDownloadUserList({users, messages, combinedID, eventStartDate})
                      }}
                    >
                      Export Users
                    </Button>
                  </ListItem>
                </List>
            </Paper>
          </Grow>
        )}
      </Popper>
      {isPlayerOpen ? <PlayerPopper setOpenState={state => setPlayerOpen(state)} /> : null}
      {showSaveChat ? <SaveChat onSave={() => setShowSaveChat(false)}/> : null}
      {showExportUsers ? <ExportUsers onExport={() => setShowExportUsers(false)} /> : null}
    </div>
    </ClickAwayListener>
  )
}

const mapStateToProps = state => {
  const thisUser = selectors.getCurrentMember(state)
  if (!thisUser) {
    return { isLoggedIn: false }
  }
  const userType = thisUser.type
  return { isLoggedIn: true, userType }
}

export default connect(mapStateToProps)(SettingsButton)
