import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'

import actions from '../../lib/actions'
import selectors from '../../store/selectors'
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core'

export const UnconnectedStartChatButton = props => {
  const {
    chatStarted,
    chatSettings,
    updateChat,
    closeMenu,
    setOpenLock
  } = props
  const [warningIsOpen, setWarningIsOpen] = useState(false)
  const handleClose = () => {
    setOpenLock(false)
    setWarningIsOpen(false)
  }
  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          setWarningIsOpen(true)
          setOpenLock(true)
        }}
      >
        {chatStarted ? 'Stop' : 'Start'} Chat
      </Button>
      <Dialog
        open={warningIsOpen}
        onClose={() => handleClose()}
        fullWidth
      >
        <DialogTitle>
        {chatStarted ? 'Stop' : 'Start'} Event Chat
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {chatStarted ? 'stop' : 'start'} chat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            color='secondary'
            variant='contained'
            onClick={() => {
              chatSettings.started = !chatStarted
              chatSettings.isChatEnabled = chatSettings.started
              chatSettings.enabled = true
              updateChat(chatSettings)
              closeMenu()
            }}
          >
            {chatStarted ? 'Stop' : 'Start'} chat
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateChat: chatSettings => {
      dispatch(actions.updateChatSettings({chatSettings}))
    },
  }
}

const mapStateToProps = state => {
  const chatSettings = selectors.getChatSettings(state)
  const chatStarted = !!chatSettings.started
  return { chatSettings, chatStarted }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedStartChatButton)
