export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const MESSAGE_CREATED = 'MESSAGE_CREATED'
export const MESSAGE_UPDATED = 'MESSAGE_UPDATED'
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_RESPONSE = 'DELETE_MESSAGE_RESPONSE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const ON_MESSAGE_VIEW = 'ON_MESSAGE_VIEW'
export const ON_MESSAGE_REPLY = 'ON_MESSAGE_REPLY'
export const ON_MESSAGE_SELECT = 'ON_MESSAGE_SELECT'
export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_MESSAGES_RESPONSE = 'GET_MESSAGES_RESPONSE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const MESSAGE_REPLY = 'MESSAGE_REPLY'
export const POLL_VOTE_REQUEST = 'POLL_VOTE_REQUEST'
export const GET_UPLOAD_URL = 'GET_UPLOAD_URL'
export const GET_DOWNLOAD_URL = 'GET_DOWNLOAD_URL'
export const GET_DOWNLOAD_URL_REQUEST = 'GET_DOWNLOAD_URL_REQUEST'
export const UPLOADING_FILE = 'UPLOADING_FILE'
export const UPLOADED_FILE = 'UPLOADED_FILE'
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR'
export const SET_MESSAGE_ATTACHMENT_URL = 'SET_MESSAGE_ATTACHMENT_URL'
export const CHANGE_SHOW_EDIT_MESSAGE = 'CHANGE_SHOW_EDIT_MESSAGE'