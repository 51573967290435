import React from 'react'
import { connect } from 'react-redux'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

import selectors from '../../../../store/selectors'
import MenuItem from './item'

export const UnconnectedAttachmentsDropDown = props => {
  const { attachments, count, messageId, isByThisUser } = props

  if (!count) {
    return null
  }

  return (
    <FormControl variant='outlined' style={{width:'10rem', display:'flex', float:isByThisUser ? 'right' : 'left'}}>
      <InputLabel>
        Attachments {count}
      </InputLabel>
      <Select value='' >
        {attachments.map(attachment => {
          // eslint-disable-next-line react/display-name
          return <MenuItem key={attachment.assetId + 'menuItem'} assetId={attachment.assetId} messageId={messageId}/>
        })}
      </Select>
    </FormControl>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { messageID, isSubmission } = ownProps
  const message = isSubmission ? state.submission : selectors.getMessageByID(state, messageID)
  const attachments = message.content.attachments
  const count = attachments && attachments.length
  const thisUser = selectors.getCurrentMember(state)
  const isByThisUser = thisUser && thisUser.userId === message.memberID
  return { attachments, count, messageId: messageID, isByThisUser }
}

export default connect(mapStateToProps)(UnconnectedAttachmentsDropDown)
