/*global document, getComputedStyle, window */
import React, { useLayoutEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { List } from 'react-virtualized'
import { Button, makeStyles } from '@material-ui/core'

import Sidebar from './sidebar'
import constants from '../../lib/constants'
import Item from './item'
import actions from '../../lib/actions'
import selectors from '../../store/selectors'

const convertRemToPixels = (rem) => {
  const oneRemPx = getComputedStyle(document.documentElement).fontSize //return ex: "16px"
  return rem * parseFloat(oneRemPx.substring(0, oneRemPx.length - 2))
}

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

const InnerItem = React.memo(
  (props) => {
    const { item, changeSideTab, mainTab, thisUser, i } = props
    const isPoll = mainTab === constants.CHAT.POLL
    const roomName = item.name || item.userId
    return (
      <Item
        selected={item.selected}
        name={item.name || null}
        userId={item.userId || null}
        notificationCount={item.notificationCount}
        hasNew={item.hasNew}
        handleClick={() => {
          let subTab
          if (i === 0) {
            subTab = mainTab
          } else {
            if (mainTab === constants.CHAT.POLL) {
              subTab = item.messageId
            } else {
              subTab = item.userId
            }
          }
          changeSideTab(subTab, thisUser, roomName, isPoll)
        }}
      />
    )
  },
  (nextProps, prevProps) => {
    // return whether this component is the same with the next props
    if (prevProps.mainTab !== nextProps.mainTab) return false
    return (
      prevProps.selected !== nextProps.selected ||
      prevProps.notificationCount !== nextProps.notificationCount ||
      prevProps.hasNew !== nextProps.hasNew
    )
  }
)

const PageButtons = (props) => {
  const { maxHeight, curPage, totalPages, onNext, onPrev } = props
  const classes = useStyles()
  return (
    <div className={classes.paginationContainer} style={{ maxHeight }}>
      {curPage > 1 ? (
        <Button size='small' variant='contained' color='primary' onClick={onPrev}>
          Previous
        </Button>
      ) : null}
      {curPage < totalPages ? (
        <Button size='small' variant='contained' color='primary' onClick={onNext}>
          Next
        </Button>
      ) : null}
    </div>
  )
}



const InnerSidebar = (props) => {
  const { header, items, changeSideTab, mainTab, thisUser } = props
  const [rowWidth, setRowWidth] = useState(100)
  const [page, setPage] = useState(0)
  let width = parseInt(constants.STYLES.INNER_DRAWER_WIDTH, 10) + 1 + 'rem'
  if (convertRemToPixels(width) > window.innerWidth / 3) {
    width = '33%'
  }

  const sidebarRef = useRef(null)

  useLayoutEffect(() => {
    if (sidebarRef.current && sidebarRef.current.clientWidth !== rowWidth) {
      setRowWidth(sidebarRef.current.clientWidth)
      setPage(0)
    }
  }, [sidebarRef])

  const sidebarSearch = useSelector((state) => state.sidebar.search)
  useLayoutEffect(() => {
    setPage(0)
  }, [sidebarSearch])

  const itemHeight = 96
  const headerHeight = 64
  const perPage = 40
  const paginationHeight = 37
  const itemsInPage = Math.min(perPage, items.length - perPage * page)

  const rowRenderer = ({ index, key }) => {
    const item = index === 0 ? items[0] : items[index + perPage * page - page]
    return (
      <InnerItem
        item={item}
        changeSideTab={changeSideTab}
        mainTab={mainTab}
        thisUser={thisUser}
        i={index}
        key={key}
      />
    )
  }
  return (
    <>
      <Sidebar
        type='sub'
        innerRef={sidebarRef}
        header={header}
        styles={{
          paddingLeft: '0.4rem',
          width: width,
          border: '0',
        }}
      >
        <List
          height={itemHeight * itemsInPage - (itemHeight - headerHeight) + paginationHeight}
          rowHeight={(e) => (e.index === 0 ? headerHeight : itemHeight)}
          // rowHeight={index => index % 2 == 0 ? headerHeight : itemHeight}
          rowCount={itemsInPage}
          rowRenderer={rowRenderer}
          width={rowWidth}
        />
      </Sidebar>
      <PageButtons
        maxHeight={paginationHeight}
        curPage={page + 1}
        totalPages={Math.ceil((items.length - 1) / perPage)}
        onNext={() => setPage(page + 1)}
        onPrev={() => setPage(page - 1)}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSideTab: (subTab, thisUser, roomName, isPoll) => {
      dispatch(actions.changeSubTab({ subTab }))
      dispatch(actions.changeRoom({ room: subTab, thisUser, roomName, isPoll }))
      if (subTab === 'Poll') {
        dispatch(actions.changeSubmissionShowPoll({ showPoll: true }))
      } else {
        dispatch(actions.changeSubmissionShowPoll({ showPoll: false }))
      }
    },
  }
}

/**
 * @summary sorted in the order:
 *    1) thisUser
 *    2) users with unseen messages
 *    3) users who are online
 *    4) Awaiting Reply
 *    5) count of total conversation messages with a user
 *    6) alphabetical on name
 *
 * @param {string[]} users an array of users to compare sidebar position for
 * @param {Object} state the redux state
 */
export const sortUsers = (users, state) => {
  const sortFunc = (b, a) => {
    if (a === state.members.thisUser) return 1
    if (b === state.members.thisUser) return -1
    const aHasNew = !!selectors.getUnseenRoomMessages(state, a).length
    const bHasNew = !!selectors.getUnseenRoomMessages(state, b).length
    if (aHasNew && !bHasNew) {
      return 1
    }
    if (!aHasNew && bHasNew) {
      return -1
    }
    const aIsOnline = state.members.data[a] && state.members.data[a].isOnline
    const bIsOnline = state.members.data[b] && state.members.data[b].isOnline
    if (aIsOnline && !bIsOnline) {
      return 1
    }
    if (!aIsOnline && bIsOnline) {
      return -1
    }
    const aIsAwaitingReply =
      state.members.data[a] &&
      state.members.data[a].options &&
      state.members.data[a].options.awaitTime > state.members.data[a].options.awaitingReply
    const bIsAwaitingReply =
      state.members.data[b] &&
      state.members.data[b].options &&
      state.members.data[b].options.awaitTime > state.members.data[b].options.awaitingReply
    if (aIsAwaitingReply && !bIsAwaitingReply) {
      return 1
    }
    if (!aIsAwaitingReply && bIsAwaitingReply) {
      return -1
    }
    const aMessages = selectors.getRoomMessages(state, a).length
    const bMessages = selectors.getRoomMessages(state, b).length
    if (aMessages !== bMessages) {
      return aMessages - bMessages
    }
    if (b < a) {
      return -1
    } else if (b > a) {
      return 1
    }
    return 0
  }
  const sorted = users.sort(sortFunc)
  // Logger.log("sort func:", sortFunc)
  return sorted
}

const mapStateToProps = (state) => {
  const thisUser = selectors.getCurrentMember(state)
  const mainTab = state.sidebar.mainTab
  const subTab = state.sidebar.subTab
  const sidebarSearch = state.sidebar.search
  let group = selectors.getMemberIDsInGroup(state, mainTab || constants.CHAT.GENERAL)
  let header
  const items = [
    {
      selected: subTab === mainTab,
      name: 'Group Messages',
      notificationCount: selectors.getRoomMessages(state, mainTab).length,
      hasNew: !!selectors.getUnseenRoomMessages(state, mainTab).length,
    },
  ]

  switch (mainTab) {
    case constants.CHAT.GENERAL:
      header = 'Visitors'
      items[0].name = 'Posted Messages'
      break
    case constants.CHAT.MODERATOR:
      header = 'Moderators'
      break
    case constants.CHAT.POLL:
      header = 'Polls'
      items[0].name = 'Create A Poll'
      group = selectors.getPastPolls(state)
      break
    default:
      header = ''
  }
  // set the items of each main tab
  // members of group for user and moderators, poll names for poll tab
  if (mainTab !== constants.CHAT.POLL) {
    group = sortUsers(group, state)
    if (sidebarSearch.length) {
      group = group.filter((userId) => {
        const user = state.members.data[userId]
        return user.name.toLowerCase().includes(sidebarSearch.toLowerCase())
      })
    }
    group.forEach((user) => {
      items.push({
        selected: subTab === user,
        userId: user,
        notificationCount: selectors.getRoomMessages(state, user).length,
        hasNew: !!selectors.getUnseenRoomMessages(state, user).length,
      })
    })
  } else {
    // sorting polls by their time posted
    group = group.sort((a, b) => {
      if (a.timePosted > b.timePosted) {
        return 1
      } else if (a.timePosted <= b.timePosted) {
        return -1
      }
    })
    group.forEach((message) => {
      items.push({
        selected: subTab === message.id,
        name: message.content.poll.label,
        messageId: message.id,
      })
    })
  }

  return { header, items, mainTab, thisUser }
}

export default connect(mapStateToProps, mapDispatchToProps)(InnerSidebar)
