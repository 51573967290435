export const SHRINK_SIDEBAR = 'SHRINK_SIDEBAR'
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR'
export const EXPAND_MAIN_SIDEBAR = 'EXPAND_MAIN_SIDEBAR'
export const EXPAND_SUB_SIDEBAR = 'EXPAND_SUB_SIDEBAR'
export const SHRINK_MAIN_SIDEBAR = 'SHRINK_MAIN_SIDEBAR'
export const SHRINK_SUB_SIDEBAR = 'SRINK_SUB_SIDEBAR'
export const CHANGE_MAIN_TAB = 'CHANGE_MAIN_TAB'
export const CHANGE_SUB_TAB = 'CHANGE_SUB_TAB'
export const SHOW_SIDEBAR_MOBILE = 'SHOW_SIDEBAR_MOBILE'
export const HIDE_SIDEBAR_MOBILE = 'HIDE_SIDEBAR_MOBILE'
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH'
export const CHANGE_SIDEBAR_SEARCH = 'CHANGE_SIDEBAR_SEARCH'