import React from 'react'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import DesktopMacIcon from '@material-ui/icons/DesktopMacTwoTone'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import MailIcon from '@material-ui/icons/Mail';

import selectors from '../../store/selectors'
import { OverlappingNotification } from '../notifications'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    margin: 10,
    width: '2.5rem',
    height: '2.5rem'
  },
  blankSpacing: {
    width: '100%',
    marginTop: '1rem',
  },
  text: {
    maxWidth: '100%',
    marginLeft: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textContainer: {
    overflow:'hidden'
  },
  tooltip: {
    paddingTop:'1rem'
  },
  greenBackground: {
    color: 'green'
  },
  iconRow: {
    marginLeft: '1rem',
    width: '100%'
  },
  awaitingReplyIcon: {
    // float: 'right',
    color: '#ffd040'
  }
}))

export const UnconnectedUser = props => {
  const {
    name,
    email,
    avatarSrc,
    isSelf,
    notificationCount,
    hasNew,
    isOnline,
    peopleInRoom,
    userIsNull,
    isAwaitingReply
  } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={3}>
          {
            notificationCount ?
            <OverlappingNotification count={notificationCount} hasNew={hasNew}>
              <Avatar alt={name} src={avatarSrc} className={classes.avatar}/>
            </OverlappingNotification> :
            <Avatar alt={name} src={avatarSrc} className={classes.avatar}/>
          }
        </Grid>
        <Grid item xs={9}>
          <Grid container>
              <Grid item xs={12} >
                <div className={classes.text}>{name || 'Not Logged In'}</div>
              </Grid>
            {
              !isSelf ?
              <div className={classes.iconRow}>
                <Tooltip title={isOnline ? 'Online' : 'Offline'} enterDelay={300}>
                  <DesktopMacIcon className={clsx(isOnline && classes.greenBackground)}/>
                </Tooltip>
                {peopleInRoom.length === 1 ?
                  <Tooltip title={'In Chat:' + peopleInRoom} enterDelay={300}>
                    <PersonIcon />
                  </Tooltip> : null}
                {peopleInRoom.length > 1 ?
                <Tooltip title={'In Chat:' + peopleInRoom} enterDelay={300}>
                  <PeopleIcon />
                </Tooltip> : null}
                {isAwaitingReply ?
                  <Tooltip title={'Awaiting Reply'} enterDelay={300}>
                    <MailIcon className={classes.awaitingReplyIcon}/>
                  </Tooltip> : null
                }
              </div> :
              <div className={classes.blankSpacing}/>
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const user = selectors.getMemberByID(state, ownProps.userId)
  const name = user.name
  const userId = user.userId
  const avatarSrc = user.picture
  const isOnline = user.isOnline
  const isSelf = user.userId === state.members.thisUser
  const isAwaitingReply = selectors.getRoomIsAwaitingReply(state, ownProps.userId)
  const peopleInRoom = selectors.getMemberIDsInRoom(state, ownProps.userId).map(userId => selectors.getMemberByID(state, userId)).filter(member => member.isOnline).map(onlineMember => onlineMember.name)
  return { userId, name, avatarSrc, isOnline, isSelf, peopleInRoom, pending:false, isAwaitingReply }
}

export default connect(mapStateToProps)(UnconnectedUser)
