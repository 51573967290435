/*global window, document */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Draggable from 'react-draggable'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import HeightIcon from '@material-ui/icons/Height'

import selectors from '../../store/selectors'

export const UnconnectedPlayerPopper = props => {
  const { src, setOpenState, clientId, eventId, useIFrame } = props
  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)
  const [resizing, setResizing] = useState(false)
  const [videoWidth, setVideoWidth] = useState(500)
  const [videoHeight, setVideoHeight] = useState(282)
  const [startPosition, setStartPosition] = useState(null)
  const [startVideoSize, setStartVideoSize] = useState({width: 500, height: 282})

  document.documentElement.onmousemove = e => {
    if (resizing) {
      const newWidth = startVideoSize.width - (startPosition.x - e.clientX)
      const newHeight = startVideoSize.height - (startPosition.y - e.clientY)
      if (newWidth !== videoWidth) {
        setVideoWidth(String(newWidth))
      }
      if (newHeight !== videoHeight) {
        setVideoHeight(String(newHeight))
      }
    }
  }

  document.documentElement.onmouseup = () => {
    if (resizing) {
      setResizing(false)
    }
  }

  return (
    <Popper open={true} anchorEl={document.body} placement='right' style={{zIndex:10000}}>
      <Draggable
        handle='#player-grab-handle'
        bounds={{left:-videoWidth + 30, top:0, right: window.innerWidth - videoWidth - 50, bottom: window.innerHeight - videoHeight - 50}}
        position={{x:posX, y:posY}}
        onDrag={(e, position) => {
          setPosX(position.x)
          setPosY(position.y)
        }}
      >
        <Paper>
          <div style={{minHeight:'1.7rem', cursor:'grab', backgroundColor:'#004fca70', zIndex:10001}} id='player-grab-handle' >
            <IconButton
              style={{float: 'right', color:'white', borderRadius: 0, margin: '1px 1px -1px 1px', padding: '1px'}}
              onClick={() => setOpenState(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {useIFrame ?
            <iframe
              title='player popout'
              src={`https://player.invintus.com/index.html?clientID=${clientId}&eventID=${eventId}&simple=true`}
              width={videoWidth}
              height={videoHeight}
              frameBorder='0'
              allowFullScreen
            /> :
            <video width={videoWidth} height={videoHeight} controls>
              <source src={src} />
            </video>
          }
          <IconButton
            style={{padding:0}}
            onMouseDown={e => {
              setStartPosition({x: e.clientX, y: e.clientY })
              setStartVideoSize({width: videoWidth, height: videoHeight})
              setResizing(true)
            }}
            onMouseUp={() => {
              setResizing(false)
              setStartPosition(null)
              setStartVideoSize({width: videoWidth, height: videoHeight})
            }}
          >
            <HeightIcon
              style={{
                transform:'rotate(-45deg)',
                cursor:'all-scroll',
                margin: '23px -8px -4px -25px',
                fontSize: '22px',
                color: 'black'
              }}
            />
          </IconButton>
        </Paper>
      </Draggable>
    </Popper>
  )
}

const mapStateToProps = state => {
  const src = selectors.getEventVideoSrc(state)
  const eventData = state.chat && state.chat.eventData
  const eventId = eventData.eventID
  const clientId = eventData.clientID
  let useIFrame = false
  if (src === null) {
    useIFrame = true
  }
  return { src, eventId, clientId, useIFrame }
}

export default connect(mapStateToProps)(UnconnectedPlayerPopper)
