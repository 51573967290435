import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import LoginRedirect from '../login'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() =>({
  noPadding: {
    padding: 0
  }
}))

export const UnconnectedLogin = props => {
  const { isTextButton, noPadding } = props
  const [login, setLogin] = useState(false)
  const classes = useStyles()
  return (
      <Button
        className={noPadding ? classes.noPadding : ''}
        variant={isTextButton ? 'text' : 'contained'}
        color='primary'
        onClick={() => setLogin(true)}
      >
        Login
      {login ? <LoginRedirect /> : null}
      </Button>
  )
}

export default UnconnectedLogin
