import { useEffect } from 'react'
import { connect } from 'react-redux'
import csvStringify from 'csv-stringify'
import { download } from './save-chat'

const ExportUsers = (props) => {
  const { onExport, csvData, fileName, headers } = props
  useEffect(() => {
    csvStringify(csvData,
    {
      header: true,
      headers
    }, (err, data) => {
      if (err) {
        console.error('Error downloading chat users:', err)
      }
      download(data, fileName, 'text/csv')
      onExport()
    })
  }, [])
  return null
}

const mapStateToProps = (state) => {
  const headers = ['name', 'type', 'time last visited']
  const { members } = state
  const csvData =
    Object.keys(members.data).map((memberKey) => {
      const member = members.data[memberKey]
      const name = member.name
      const type = member.type
      const timesLastVisited =
        member.lastIn &&
        Object.keys(member.lastIn).map((visitedRoomId) => {
          // room could be "Public", "Moderator", "Poll", a message id for a poll or a user Id
          // ignore message ids and poll rooms

          if (members.data[visitedRoomId]) {
            // matching userId to roomId
            return `${members.data[visitedRoomId].name}:${new Date(
              member.lastIn[visitedRoomId]
            ).toLocaleString()}`
          } else if (['Moderator', 'Public'].includes(visitedRoomId)) {
            // matching group room to roomId
            return `${visitedRoomId}:${new Date(member.lastIn[visitedRoomId]).toLocaleDateString()}`
          }
        })
      return {
        name,
        type,
        'time last visited': timesLastVisited,
      }
    }) || []
  const fileName =
    'Chat Users ' +
    state.chat.eventData.combinedID +
    ' ' +
    state.chat.eventData.startDateTime +
    '.csv'
  return { csvData, fileName, headers }
}

export default connect(mapStateToProps)(ExportUsers)
