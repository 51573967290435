import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import IconButton from '@material-ui/core/IconButton'

import constants from '../../lib/constants'
import { Notification } from '../notifications'
import User from '../user'
import selectors from '../../store/selectors'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #e4e4e4',
    color: '#a2abae',
    padding: '1rem 0',
    paddingLeft: '0.5rem',
    height: 'auto',
    minHeight: '4rem',
    maxHeight: '6rem',
    transition: 'filter 0.3s ease',
    '&:hover': {
      filter: 'brightness(85%)',
      cursor: 'pointer',
    }
  },
  selectedRoot: {
    border: '1px solid #e4e4e4',
    backgroundColor: constants.COLORS.BLUE,
    color: 'white',
    margin: 0,
  },
  defaultIconButton: {
    color: '#8b8c8b',
  },
  iconButtons: {
    padding: 0,
    margin: 0
  },
  icons: {
    fontSize: '1.5rem',
    paddingTop: '0.3rem'
  },
  userContainer: {
    minWidth: props => props.type === 'sub' ? constants.STYLES.INNER_DRAWER_WIDTH :  constants.STYLES.DRAWER_WIDTH,
    wordBreak: 'break-all',
    paddingBottom: '0.5rem'
  },
  oneMr: {
    marginRight: '1rem'
  },
  halfMr: {
    marginRight: '0.5rem'
  }
}))

const Item = props => {
  const { selected, name, notificationCount, hasNew, handleClick, userId, type } = props
  const classes = useStyles({type})
  return (
    <div className={clsx(classes.root, selected && classes.selectedRoot)} onClick={handleClick}>
      <Grid container justify='center' alignItems='center' className={classes.item}>
        <Grid container justify='center' alignItems='center' className={classes.oneMr}>
          <Grid item xs={9} className={classes.firstItem}>
            {name && userId ?
              <div className={classes.userContainer}>
                <User userId={userId} name={name} notificationCount={notificationCount} hasNew={hasNew}/>
              </div> :
            <div>{name}</div>}
          </Grid>
          <Grid item xs={2} >
            <Grid container justify='center' alignItems='center'>
              <Grid item xs={2}className={classes.halfMr}>
                {userId ?
                null :
                 <Notification count={notificationCount} hasNew={hasNew}/>}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label='Item'
                  edge='end'
                  color='inherit'
                  className={clsx(classes.iconButtons, !selected && classes.defaultIconButton)}
                  onClick={handleClick}
                >
                  <ArrowForwardIosIcon className={classes.icons}/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let { userId, name } = ownProps
  if (userId) {
    name = state.members.data[userId].name
  }
  const awaitTime = selectors.getRoomAwaitTime(state, userId)
  return { userId, name, awaitTime }
}

export default connect(mapStateToProps)(Item)
