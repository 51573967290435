import {
  CHANGE_ROOM,
  SET_USER_MUTE,
  SET_THIS_USER,
  GET_MEMBERS_REQUEST,
  UPDATE_USER_REQUEST,
  SET_AWAITING_TIME,
} from './types'

import constants from '../../lib/constants'
import actions from '../../lib/actions'
import { sendMessage } from '../chat/actions'
import selectors from '../selectors'

export const setAwaitTime = payload => {
  return dispatch => {
    dispatch({type: SET_AWAITING_TIME, payload})
    dispatch(sendMessage({type: 'SET_AWAITING_TIME', data: payload}))
  }
}

export const setThisUser = payload => {
  return dispatch => {
    payload.user.authName = payload.user.name
    delete payload.user.name
    dispatch({type: SET_THIS_USER, payload})
    dispatch(sendMessage({type: 'CREATE_USER', data: payload.user}))
  }
}

/**
 *  Check that if the user is not a moderator that they are moved into the public chat if they weren't there before
 * @param {*} payload 
 */
export const checkThisUserRoom = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const thisUser = selectors.getCurrentMember(state)
    if (
      thisUser &&
      thisUser.type === constants.MEMBER_TYPES.MEMBER &&
      payload[thisUser.userId].currentRoom !== constants.CHAT.GENERAL
    ) {
      dispatch(actions.changeRoom({
        room: constants.CHAT.GENERAL,
        thisUser,
        roomName: state.chat.eventData.chat.chatTypeHeading || 'Public Discussion'
      }))
    }
  }
}

export function getMembers(payload) {
  // Logger.log('called get members:', payload)
  return dispatch => {
    dispatch({type: GET_MEMBERS_REQUEST, payload})
    dispatch(sendMessage({
      type:'GET_EVENT_USERS',
      data: { email: payload.email && payload.email.toLowerCase(), sub: payload.sub }
    }))
  }
}

export function changeRoom(payload) {
  return dispatch => {
    dispatch({type:CHANGE_ROOM, payload})
    if (!payload.isPoll) {
      dispatch(sendMessage({
        type: CHANGE_ROOM,
        data: {room: payload.room}
      }))
    }
  }
}

export const updateUser = payload => {
  return dispatch => {
    delete payload.authName
    dispatch(sendMessage({
      type: UPDATE_USER_REQUEST,
      data: {...payload}
    }))
  }
}

export function setUserMute(payload) {
  return {type: SET_USER_MUTE, payload}
}
