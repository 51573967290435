
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { useAuth0 } from '../../auth/react-auth0-wrapper'
import { connect } from 'react-redux'

import selectors from '../../store/selectors'
import actions from '../../lib/actions'
import constants from '../../lib/constants'

const mapStateToProps = state => {
  const thisUser = selectors.getCurrentMember(state)
  const websocket = state.chat.websocket
  return {thisUser, websocket}
}

const mapDispatchToProps = dispatch => {
  return {
    setThisUser: user => {
      dispatch(actions.setThisUser({user, from:constants.FROM.O_AUTH}))
    },
    getMembers: (payload) => {
      dispatch(actions.getMembers(payload))
    },
    getMessages: payload => {
      dispatch(actions.getMessages(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  const { thisUser, setThisUser, websocket, fromEmailLink, getMembers, getMessages } = props
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()
  if (!isAuthenticated) {
    loginWithRedirect({fromEmailLink})
    return null
  } else {
    if (!websocket) return null
    // user is auth0 data
    if (user) {
      if (!thisUser || !thisUser.from) {
        setThisUser(user)
        getMembers({email: user.email, sub: user.sub})
        getMessages({email: user.email, sub: user.sub})
      }
    }
    return null
  }
})
