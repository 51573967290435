import axios from 'axios'

import {
  SUBMISSION_ATTACHMENTS_CHANGE,
  SUBMISSION_PINNED_CHANGE,
  SUBMISSION_POLL_CHANGE,
  SUBMISSION_TEXT_CHANGE,
  SUBMISSION_SHOW_POLL_CHANGE,
  START_SUBMIT_MESSAGE,
  START_PUBLISH_REPLY,
  SUBMISSION_SHOW_REPLY_CHANGE,
  SUBMISSION_PUBLISHED_MESSAGE_CHANGE,
  SUBMISSION_PUBLISHED_NAME_CHANGE,
  RESET_SUBMISSION_POLL,
  SUBMISSION_GET_UPLOAD_URL,
  SUBMISSION_UPLOADED_FILE,
  FILE_UPLOAD_ERROR,
  SUBMISSION_PUBLISHED_CHANGED,
  SUBMISSION_SAVED_CHANGE,
  LOAD_MESSAGE_INTO_SUBMISSION,
  SUBMISSION_TIMED_OUT,
  CLEAR_SUBMISSION_ERROR
} from './types'
import actions from '../../lib/actions'
import { CREATE_MESSAGE, GET_UPLOAD_URL, UPLOADING_FILE} from '../messages/types'
import selectors from '../selectors'
import constants from '../../lib/constants'
import { Logger } from '../../lib/utilities'

export const clearSubmissionError = payload => {
  return { type: CLEAR_SUBMISSION_ERROR, payload }
}

export const submissionTimeout = payload => {
  return { type: SUBMISSION_TIMED_OUT, payload }
}

export const changeSubmissionText = payload => {
  return { type: SUBMISSION_TEXT_CHANGE, payload}
}

export const changeSubmissionPinned = payload => {
  return { type: SUBMISSION_PINNED_CHANGE, payload}
}

export const changeSubmissionPoll = payload => {
  return { type: SUBMISSION_POLL_CHANGE, payload}
}

export const resetPoll = () => {
  return { type: RESET_SUBMISSION_POLL }
}

export const changeSubmissionAttachments = payload => {
  return { type: SUBMISSION_ATTACHMENTS_CHANGE, payload}
}

export const changeSubmissionShowPoll = payload => {
  return { type: SUBMISSION_SHOW_POLL_CHANGE, payload}
}

export const changeSubmissionShowReply = payload => {
  return {type: SUBMISSION_SHOW_REPLY_CHANGE, payload}
}

export const loadMessageIntoSubmission = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const message = state.messages.data[payload.messageID]
    if (!message) {
      dispatch({ type: LOAD_MESSAGE_INTO_SUBMISSION})
    } else {
      dispatch({ type: LOAD_MESSAGE_INTO_SUBMISSION, payload: {message}})
    }
  }
}

export const changeSubmissionSaved = payload => {
  return { type: SUBMISSION_SAVED_CHANGE, payload }
}

export const addPublishedMessageToSubmission = () => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedMessage = state.messages.data[state.messages.selectedMessage]
    const published = {
      userId: selectedMessage.memberID,
      messageId: selectedMessage.id,
      name: state.members.data[selectedMessage.memberID].name,
      content: selectedMessage.content
    }
    dispatch({ type: SUBMISSION_PUBLISHED_CHANGED, payload: {published}})
  }
}

export const removePublishedMessageFromSubmission = () => {
  return dispatch => {
    dispatch({ type: SUBMISSION_PUBLISHED_CHANGED, payload: {published:undefined}})
  }
}

export const changePublishedMessage = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedMessage = state.messages.data[state.messages.selectedMessage]
    payload.selectedMessage = Object.assign({}, selectedMessage)
    dispatch({ type: SUBMISSION_PUBLISHED_MESSAGE_CHANGE, payload})
  }
}

export const changePublishedName = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedMessage = state.messages.data[state.messages.selectedMessage]
    payload.selectedMessage = Object.assign({}, selectedMessage)
    dispatch({ type: SUBMISSION_PUBLISHED_NAME_CHANGE, payload})
  }
}

export const startSubmitMessage = () => {
  return (dispatch, getState) => {
    const state = getState()
    const messageData = state.submission
    const thisUser = selectors.getCurrentMember(state)
    messageData.to = thisUser.currentRoom
    if (messageData.content.poll) {
      messageData.content.poll.values = new Array(messageData.content.poll.types.length).fill(0)
      messageData.to = constants.CHAT.GENERAL
    }
    messageData.memberID = thisUser.userId
    delete messageData.showPoll
    delete messageData.showReply
    dispatch({type: START_SUBMIT_MESSAGE })
    dispatch(actions.sendMessage({ type: CREATE_MESSAGE, data: {messageData}}))
  }
}

export const startPublishReply = () => {
  return (dispatch, getState) => {
    const state = getState()
    const messageData = state.submission
    const thisUser = selectors.getCurrentMember(state)
    messageData.to = constants.CHAT.GENERAL
    messageData.memberID = thisUser.userId
    messageData.published = state.submission.published
    delete messageData.showPoll
    delete messageData.showReply
    dispatch(actions.sendMessage({ type: CREATE_MESSAGE, data: {messageData}}))
    dispatch({type: START_PUBLISH_REPLY})
  }
}

export const getUploadUrl = payload => {
  return dispatch => {
    dispatch({ type: SUBMISSION_GET_UPLOAD_URL, payload})
    dispatch(actions.sendMessage({
      type: GET_UPLOAD_URL,
      data: { assetId: payload.assetId, name: payload.name, contentType: payload.contentType}
    }))
  }
}

export const uploadFile = payload =>{
  return (dispatch, getState) => {
    dispatch({type: UPLOADING_FILE, payload})
    const { assetId, url, contentType} = payload
    const state = getState()
    let file
    for (let i = 0; i < state.submission.content.attachments.length; i++) {
      if (assetId === state.submission.content.attachments[i].assetId) {
        file = state.submission.content.attachments[i]
        break
      }
    }
    const options = {
      headers: {
        'Content-Type': contentType,
        'x-amz-acl': 'bucket-owner-full-control'
      },
    }
    axios.put(url, file, options)
      .then(() => {
        dispatch({type: SUBMISSION_UPLOADED_FILE, payload})
      }).catch(err => {
        dispatch({type: FILE_UPLOAD_ERROR, err})
        Logger.error('error uploading file:', err)
      })
  }
}
