export const SUBMISSION_TEXT_CHANGE = 'SUBMISSION_TEXT_CHANGE'
export const SUBMISSION_PINNED_CHANGE = 'SUBMISSION_PINNED_CHANGE'
export const SUBMISSION_ATTACHMENTS_CHANGE = 'SUBMISSION_ATTACHMENTS_CHANGE'
export const SUBMISSION_POLL_CHANGE = 'SUBMISSION_POLL_CHANGE'
export const SUBMISSION_SHOW_POLL_CHANGE = 'SUBMISSION_SHOW_POLL_CHANGE'
export const START_PUBLISH_REPLY = 'START_PUBLISH_REPLY'
export const START_SUBMIT_MESSAGE = 'START_SUBMIT_MESSAGE'
export const SUBMISSION_SHOW_REPLY_CHANGE = 'SUBMISSION_SHOW_REPLY_CHANGE'
export const SUBMISSION_PUBLISHED_NAME_CHANGE = 'SUBMISSION_PUBLISHED_NAME_CHANGE'
export const SUBMISSION_PUBLISHED_MESSAGE_CHANGE = 'SUBMISSION_PUBLISHED_MESSAGE_CHANGE'
export const SUBMISSION_PUBLISHED_CHANGED = 'SUBMISSION_PUBLISHED_CHANGED'
export const MESSAGE_REPLY = 'MESSAGE_REPLY'
export const ON_MESSAGE_REPLY = 'ON_MESSAGE_REPLY'
export const RESET_SUBMISSION_POLL = 'RESET_SUBMISSION_POLL'
export const SUBMISSION_GET_UPLOAD_URL = 'SUBMISSION_GET_UPLOAD_URL'
export const SUBMISSION_UPLOADED_FILE = 'SUBMISSION_UPLOADED_FILE'
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR'
export const SUBMISSION_SAVED_CHANGE = 'SUBMISSION_SAVED_CHANGE'
export const LOAD_MESSAGE_INTO_SUBMISSION = 'LOAD_MESSAGE_INTO_SUBMISSION'
export const SUBMISSION_TIMED_OUT = 'SUBMISSION_TIMED_OUT'
export const CLEAR_SUBMISSION_ERROR = 'CLEAR_SUBMISSION_ERROR'
