
export default {
  // ALWAYS:'ALWAYS',
  // NEVER:'NEVER',
  MEMBER_TYPES: {
    MEMBER:'User',
    MODERATOR:'Moderator',
    HOST:'Host',
  },
  GROUP_ROOMS: ['Public', 'Moderator', 'Poll'],
  CHAT: {
    GENERAL: 'Public',
    MODERATOR: 'Moderator',
    POLL: 'Poll',
    ALL: 'All'
  },
  CONTENT: {
    MESSAGES: 'MESSAGES',
    SETTINGS: 'SETTINGS'
  },
  FROM: {
    O_AUTH: 'o auth',
    DB: 'database'
  },
  COLORS: {
    BLUE: 'rgb(33, 150, 243)'
  },
  STYLES: {
    DRAWER_WIDTH: '11rem',
    INNER_DRAWER_WIDTH: '15rem',
    MESSAGE_CONTAINER_ROOT: {
      border: '1px solid #b0b0b9',
      borderRadius: '0.5rem',
      padding: '0.5rem',
      backgroundColor: '#fbfbff',
      height: '100%',
      overflow:'auto'
    },
    DEFAULT_POLL_HEIGHT: 30
  }
}