import React from 'react'
import Button from '@material-ui/core/Button'
import { useAuth0 } from '../../auth/react-auth0-wrapper'

const LogoutButton = () => {
  const { logout } = useAuth0()
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={() => {
        // eslint-disable-next-line no-undef
        logout({returnTo: window.location})
      }}
    >
      Logout
    </Button>
  )
}

export default LogoutButton
