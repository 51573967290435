import React from 'react'

import Header from './header'
import Content from './content'

const Message = props => {
  const { messageID } = props
  return (
    <div>
      <Header messageID={messageID} />
      <Content messageID={messageID} />
    </div>
  )
}
export default Message
