import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import { getTimeFormat } from '../../../lib/utilities'
import selectors from '../../../store/selectors'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  nameEllipsis: {
    maxWidth: 'calc(100% - 17ch)',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  name: {
    color: '#999',
    textAlign: 'center'
  },
  date: {
    color: '#4b4b4b'
  },
  reverse: {
    flexDirection: 'row-reverse',
    textAlign: 'right'
  }
}))

export const TimePosted = props => {
  const { timePosted, realTimePosted } = props
  return (
    <Tooltip title={String(realTimePosted)} enterDelay={1000}>
      <div>
        {timePosted.date} {timePosted.time}
      </div>
    </Tooltip>

  )
}

export const UnconnectedHeader = props => {
  const { name, timePosted, postedByThisUser, realTimePosted } = props
  const classes = useStyles()
  return (
    <div className={clsx(postedByThisUser && classes.reverse, classes.root)}>
      <TimePosted timePosted={timePosted} realTimePosted={realTimePosted}/>
      <span className={classes.nameEllipsis}>{name}</span>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { messageID } = ownProps
  // for message reply preview there is no messageID
  if (!messageID) return {}
  const message = selectors.getMessageByID(state, messageID)
  const messageCreator = selectors.getMemberByID(state, message.memberID) || 'unknown'

  const thisUser = selectors.getCurrentMember(state)
  const name = messageCreator.name || messageCreator.userId
  const postedByThisUser = thisUser && message.memberID === thisUser.userId
  const timePosted = getTimeFormat(message.timePosted)
  const realTimePosted = new Date(message.timePosted)
  return { name, timePosted, postedByThisUser, realTimePosted}
}

export default connect(mapStateToProps)(UnconnectedHeader)
