import {
  connectToChat,
  getUser,
  updateChatSettings,
  connectToEvent,
  sendMessage,
} from '../store/chat/actions'
import { getMembers, changeRoom, setThisUser, updateUser, setAwaitTime } from '../store/members/actions'
import {
  updateMessagePoll,
  editMessage,
  getDownloadFileUrl,
  deleteMessage,
  selectMessage,
  getMessages,
  changeShowEdit,
} from '../store/messages/actions'
import {
  shrinkMainSidebar,
  expandMainSidebar,
  changeMainTab,
  changeSubTab,
  changeSearch
} from '../store/sidebar/actions'
import { changeContent } from '../store/content/actions'
import {
  changeSubmissionAttachments,
  changeSubmissionPoll,
  changeSubmissionSaved,
  loadMessageIntoSubmission,
  resetPoll,
  changeSubmissionText,
  changeSubmissionShowPoll,
  changeSubmissionShowReply,
  startSubmitMessage,
  startPublishReply,
  changePublishedMessage,
  changePublishedName,
  getUploadUrl,
  uploadFile,
  addPublishedMessageToSubmission,
  removePublishedMessageFromSubmission,
  submissionTimeout,
  clearSubmissionError
} from '../store/submission/actions'

export default {
  changeSearch,
  clearSubmissionError,
  submissionTimeout,
  changeShowEdit,
  setAwaitTime,
  sendMessage,
  connectToChat,
  loadMessageIntoSubmission,
  getMembers,
  // createMessage,
  deleteMessage,
  updateMessagePoll,
  changeRoom,
  // shrinkSidebar,
  // expandSidebar,
  shrinkMainSidebar,
  expandMainSidebar,
  // shrinkSubSidebar,
  // expandSubSidebar,
  changeMainTab,
  changeSubTab,
  // setUserMute,
  editMessage,
  // onMessageReply,
  // onMessageView,
  selectMessage,
  getUser,
  setThisUser,
  getMessages,
  // getUploadFileUrl,
  getDownloadFileUrl,
  uploadFile,
  // reconnectAllUsers,
  updateChatSettings,
  // checkThisUserRoom,
  // showSidebarMobile,
  // hideSidebarMobile,
  updateUser,
  connectToEvent,
  // changeSidebarWidth,
  changeContent,
  changeSubmissionAttachments,
  // changeSubmissionPinned,
  changeSubmissionPoll,
  changeSubmissionSaved,
  resetPoll,
  changeSubmissionText,
  changeSubmissionShowPoll,
  changeSubmissionShowReply,
  startPublishReply,
  startSubmitMessage,
  changePublishedMessage,
  changePublishedName,
  getUploadUrl,
  addPublishedMessageToSubmission,
  removePublishedMessageFromSubmission,
}
