import {
  // CREATE_MESSAGE_SUCCESS,
  // UPDATE_MESSAGE_POLL_SUCCESS,
  MESSAGE_UPDATED,
  MESSAGE_CREATED,
  EDIT_MESSAGE_SUCCESS,
  ON_MESSAGE_VIEW,
  ON_MESSAGE_SELECT,
  CREATE_MESSAGE_REQUEST,
  GET_MESSAGES_RESPONSE,
  DELETE_MESSAGE_RESPONSE,
  SET_MESSAGE_ATTACHMENT_URL,
  GET_DOWNLOAD_URL_REQUEST,
  CHANGE_SHOW_EDIT_MESSAGE
} from './types'
import {
  CHANGE_ROOM
} from '../members/types'
// import { CHAT_ON_CLOSE } from '../chat/types'
import { CHANGE_SUB_TAB } from '../sidebar/types'

const getInitialState = () => (
  {
    data: {},
    newMessages: [],
    loaded: false,
    error: false,
    selectedMessage: null,
    showEdit: false
  }
)
const reducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case CHANGE_ROOM: {
      const clone = Object.assign({}, state)
      Object.keys(clone.data).forEach(key => {
        const message = clone.data[key]
        // add message to newMessages if is about to be seen for the first time
        // -user hasn't been to the room before
        const newRoom = !action.payload.lastIn
        // -the message was posted after they were last in the room
        const newRecentMessage = message.timePosted > action.payload.lastIn
        // -hasn't been seen before
        const seen = message.seen
        // -is in the room about to be changed into
        const inNextRoom = message.to === action.payload.room || (message.to === action.payload.thisUser.userId && message.memberID === action.payload.room)
        if (((newRoom || newRecentMessage) && !seen ) && inNextRoom) {
          clone.newMessages.push(message.id)
        }
        // if the user is changing to this room they will see the message
        if (inNextRoom) {
          clone.data[key].seen = true
        }
      })
      // new messages are all of the ones not in the room being left
      clone.newMessages = clone.newMessages.filter(key => {
        const prevRoom = action.payload.previousRoom
        return clone.data[key].to !== prevRoom && clone.data[key].memberID !== prevRoom
      })
      if (clone.showEdit) delete clone.showEdit
      return clone
    }
    case CREATE_MESSAGE_REQUEST: {
      const clone = Object.assign({}, state)
      const message = action.payload.message
      message.pending = true
      clone.data[message.id] = message
      return clone
    }
    case GET_MESSAGES_RESPONSE: {
      const clone = Object.assign({}, state)
      clone.loaded = true
      clone.pending = false
      const newMessages = action.payload
      clone.data = {}
      Object.keys(newMessages).forEach(index => {
        clone.data[newMessages[index].id] = newMessages[index]
      })
      return clone
    }
    case MESSAGE_CREATED: {
      let clone = Object.assign({}, state)
      // Logger.log("message created reducer :", action)
      const message = action.payload
      const id = message.id
      // clone = Object.assign({}, clone, {data: Object.assign({}, clone.data, {[id]: message })})
      // return clone
      return {
        ...clone,
        data: {
          ...clone.data,
          [id]: message
        }
      }
    }
    // case ON_MESSAGE_REPLY: {
    //   let clone = Object.assign({}, state)
    //   // Logger.log("on messagereply:", action)
    //   let message
    //   if (action.payload.member) {
    //     // non websocket call
    //     message = action.payload.message
    //     message.options.replied = action.payload.member.name
    //   } else {
    //     message = action.payload
    //     delete message.pending
    //   }
    //   clone = Object.assign({}, clone, {data: Object.assign({}, clone.data, {[message.id]: message })})
    //   return clone
    // }
    case ON_MESSAGE_VIEW: {
      const clone = Object.assign({}, state)
      // Logger.log('reducer on message view;', action.payload)
      const messageID = action.payload.messageID
      const message = clone.data[messageID]
      message.seen = true
      const newMessages = clone.newMessages
      while (newMessages.indexOf(message.id) !== -1) {
        newMessages.splice(newMessages.indexOf(message.id), 1)
      }
      clone.newMessages = newMessages
      clone.data[messageID] = message
      return clone
    }
    case ON_MESSAGE_SELECT: {
      const clone = Object.assign({}, state)
      if (clone.selectedMessage === action.payload.messageID) {
        delete clone.selectedMessage
      } else {
        clone.selectedMessage = action.payload.messageID
      }
      return clone
    }
    case CHANGE_SUB_TAB: {
      if (!state.selectedMessage) return state
      const clone = Object.assign({}, state)
      const selectedMessageRoom = clone.data[clone.selectedMessage].memberID
      if (action.payload.subTab !== selectedMessageRoom) {
        // deselect messages on room change
        clone.selectedMessage = null
      }
      return clone
    }
    case MESSAGE_UPDATED: {
      const clone = Object.assign({}, state)
      const message = action.payload
      clone.data[message.id] = Object.assign({}, message)
      return clone
    }
    case EDIT_MESSAGE_SUCCESS: {
      const clone = Object.assign({}, state)
      const message = action.payload.message
      clone.data[message.id].pending = message.pending
      clone.data[message.id].content = message.content
      clone.data[message.id].options.edited = new Date().getTime()
      return clone
    }
    case DELETE_MESSAGE_RESPONSE: {
      const clone = Object.assign({}, state)
      const message = action.payload.message
      clone.data[message.id].options.deleted = action.payload.member
      clone.data[message.id].options.edited = new Date().getTime()
      return clone
    }
    case GET_DOWNLOAD_URL_REQUEST: {
      const clone = Object.assign({}, state)
      const attachments = clone.data[action.payload.messageId].content.attachments
      attachments.forEach((attachment, i) => {
        if (attachment.assetId === action.payload.assetId) {
          clone.data[action.payload.messageId].content.attachments[i].url = 'pending'
        }
      })
      return clone
    }
    case SET_MESSAGE_ATTACHMENT_URL: {
      const clone = Object.assign({}, state)
      const attachments = clone.data[action.payload.messageId].content.attachments
      attachments.forEach((attachment, i) => {
        if (attachment.assetId === action.payload.assetId) {
          clone.data[action.payload.messageId].content.attachments[i].url = action.payload.url
        }
      })
      // clone.data[action.payload.messageId].content.attachments[action.payload.assetId].url = action.payload.url
      return clone
    }
    // case CHAT_ON_CLOSE: {
      // return getInitialState()
    // }
    case CHANGE_SHOW_EDIT_MESSAGE: {
      const clone = Object.assign({}, state)
      clone.showEdit = action.payload.showEdit
      return clone
    }
    default: {
      return state
    }
  }
}

export default reducer
