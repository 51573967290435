import React, { useState, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'

import constants from '../../../lib/constants'
import selectors from '../../../store/selectors'
import { getTimeFormat } from '../../../lib/utilities'
import Header from '../messages/header'
import Content from '../messages/content'
import actions from '../../../lib/actions'

const useStyles = makeStyles(() => ({
  baseContainer: constants.STYLES.MESSAGE_CONTAINER_ROOT,
  replyContainer: {
    marginTop: '1rem',
    height: '12rem',
    width: '49%'
  },
  name: {
    fontWeight: 'bold'
  },
  said: {
    fontWeight: 'lighter'
  },
  smallMarginTop: {
    marginTop: '0.3rem'
  },
  message: {

  },
}))

const Reply = props => {
  const { publishedMessage, publishedName, name, updatePublishedMessage, updatePublishedName, selectedMessage } = props
  const [pubName, setPubName] = useState(publishedName)
  const [pubMessage, setPubMessage] = useState(publishedMessage)
  useLayoutEffect(() => {
    if (pubName !== publishedName) {
      updatePublishedName(pubName)
    }
  }, [publishedName, updatePublishedName, pubName])

  const classes = useStyles()

  if (!selectedMessage) return null
  return <div style={{display:'flex', justifyContent: 'space-between'}}>
      <div
        className={clsx(classes.baseContainer, classes.replyContainer)}
      >
        Reply
        <Divider variant='middle' className={classes.smallMarginTop}/>
        <div className={classes.message}>
          <TextField
            label='Their comment'
            variant='outlined'
            margin='dense'
            multiline
            fullWidth
            value={pubMessage}
            onChange={e => {
              setPubMessage(e.target.value)
            }}
            onBlur={() => {
              updatePublishedMessage(pubMessage)
            }}
          />
        </div>
      </div>
      <div
        className={clsx(classes.baseContainer, classes.replyContainer)}
      >
        Message Preview
        <Divider variant='middle' className={classes.smallMarginTop}/>
        <div className={classes.smallMarginTop}>
          <Header name={name} timePosted={getTimeFormat(new Date().getTime())} postedByThisUser />
          <Content isSubmission={true} isReply={true} publishedUser={pubName} publishedMessage={pubMessage}/>
        </div>
      </div>
    </div>
}

const mapDispatchToProps = dispatch => {
  return {
    updatePublishedMessage: message => {
      dispatch(actions.changePublishedMessage({message}))
    },
    updatePublishedName: name => {
      dispatch(actions.changePublishedName({name}))
    }
  }
}

const mapStateToProps = state => {
  const selectedMessage = selectors.getMessageByID(state, state.messages.selectedMessage)
  const publishedMessage = selectedMessage && selectedMessage.content.message
  const publishedName = selectedMessage && selectedMessage.memberID
  const name = selectors.getCurrentMember(state).name
  return { publishedMessage, publishedName, name, selectedMessage }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reply)
