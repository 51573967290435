import {
  SHRINK_SIDEBAR,
  EXPAND_SIDEBAR,
  CHANGE_MAIN_TAB,
  CHANGE_SUB_TAB,
  SHOW_SIDEBAR_MOBILE,
  HIDE_SIDEBAR_MOBILE,
  SHRINK_MAIN_SIDEBAR,
  EXPAND_MAIN_SIDEBAR,
  SHRINK_SUB_SIDEBAR,
  EXPAND_SUB_SIDEBAR,
  CHANGE_SIDEBAR_WIDTH,
  CHANGE_SIDEBAR_SEARCH
} from './types'
import constants from '../../lib/constants'

const getInitialState = () => (
  {
    mainExpanded: true,
    subExpanded: true,
    showInMobile: false,
    mainTab: constants.CHAT.GENERAL,
    subTab: constants.CHAT.GENERAL,
    width: 0,
    search: ''
  }
)

const reducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case CHANGE_SIDEBAR_SEARCH:
      return {
        ...state,
        search: action.payload.search
      }
    case SHRINK_SIDEBAR:
      return Object.assign({}, state, {expanded: false})
    case EXPAND_SIDEBAR:
      return Object.assign({}, state, {expanded: true})
    case SHRINK_MAIN_SIDEBAR:
      return Object.assign({}, state, {mainExpanded: false, subExpanded: false})
    case EXPAND_MAIN_SIDEBAR:
      return Object.assign({}, state, {mainExpanded: true})
    case SHRINK_SUB_SIDEBAR:
      return Object.assign({}, state, {subExpanded: false})
    case EXPAND_SUB_SIDEBAR:
      return Object.assign({}, state, {subExpanded: true})
    case SHOW_SIDEBAR_MOBILE:
      return Object.assign({}, state, {showInMobile: true})
    case HIDE_SIDEBAR_MOBILE:
      return Object.assign({}, state, {showInMobile: false})
    // case CHANGE_ROOM:
    //     return Object.assign({}, state, {tab: action.payload.room}) // is comming in as action.room = "GENERAL"/"ALL"/"MODERATOR" use that comparing user type to show that user in the sidebar list
    case CHANGE_MAIN_TAB:
      return Object.assign({}, state, {mainTab: action.payload.mainTab, subExpanded:true})
    case CHANGE_SUB_TAB:
      return Object.assign({}, state, {subTab: action.payload.subTab})
    case CHANGE_SIDEBAR_WIDTH:
      return Object.assign({}, state, {width: action.payload.width})
    default:
      return state
  }
}

export default reducer
