import React, { useState, useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { Bar, HorizontalBar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import isEqual from 'lodash.isequal'

import VoteButton from './vote-button'
import selectors from '../../../store/selectors'
import actions from '../../../lib/actions'
import constants from '../../../lib/constants'

const useStyles = makeStyles(() => ({
  graphContainer: {
    width: '100%',
  }
}))

export const UnconnectedPoll = props => {
  let {
    poll,
    isSubmission,
    messageID,
    updateSubmissionPoll,
    updateRealPoll,
    messageText,
    openSidebarWidth,
    thisUserId
  } = props
  const classes = useStyles()
  const [graph, setGraph] = useState(null)
  // @note about shouldDestroyGraph
  // because the graph itself resizes based on the container after render
  // if the container needs to be resized the graph itself should be destroyed and then recreated
  // to get the size changes of its parent component
  const [shouldDestroyGraph, setShouldDestroyGraph] = useState(false)
  const [lastData, setLastData] = useState(null)
  const [pastHeight, setPastHeight] = useState(null)

  if (!poll) {
    poll = {
      label: 'label',
      colors: ['#ff6300'],
      types: [],
      values: [],
      voters: [],
      graphType: 'Vertical',
      height: constants.STYLES.DEFAULT_POLL_HEIGHT
    }
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation:false,
    scales:{},
    legend: {
      labels: {
        boxWidth: 0
      }
    },
    tooltips: {
      callbacks: {
        label: tooltipItem => {
          return `${poll.graphType === 'Horizontal' ? tooltipItem.yLabel : tooltipItem.xLabel}: ${tooltipItem.value}`
        },
        title: () => null
      }
    }
  }
  if (graph && poll.height !== pastHeight) {
    setPastHeight(poll.height)
    setGraph(null)
  }
  const labels = poll.types && poll.types.filter(type => type && type.length) || []
  // const baseColor = `rgb(${poll.color.r},${poll.color.g},${poll.color.b}`
  const colors = poll.colors || ['#ff1234']
  const data = {
    labels,
    datasets: [
      {
        label: poll.label,
        backgroundColor: colors.map(color => color + '66'),
        borderColor: colors,
        borderWidth: 1,
        hoverBackgroundColor: colors.map(color => color + '99'),
        hoverBorderColor: colors,
        data: poll.values
      }
    ]
  }

  const updateGraph = () => {
    if (shouldDestroyGraph) {
      setGraph(null)
      setShouldDestroyGraph(false)
    } else {
      switch (poll.graphType) {
        case 'Horizontal':
          options.scales.xAxes = [{
            ticks: {
              beginAtZero:true,
              precision: 0
            }
          }]
          setGraph(<HorizontalBar data={data} options={options} />)
          break
        case 'Vertical':
        default:
          options.scales.yAxes = [{
            ticks: {
              beginAtZero:true,
              precision: 0
            }
          }]
          setGraph(<Bar data={data} options={options} />)
      }
    }
  }
  if (shouldDestroyGraph) {
    updateGraph()
  }

  useEffect(() => {
    if (!isEqual(data, lastData)) {
      setShouldDestroyGraph(true)
      updateGraph()
      setLastData(Object.assign({}, data))
    }
    if (!graph) {
      updateGraph()
    }
  })

  const containerWidth = `calc(90vw - 10rem - ${openSidebarWidth})`
  useEffect(() => {
    setShouldDestroyGraph(true)
    updateGraph()
  }, [pastHeight, poll.graphType, openSidebarWidth])
  useLayoutEffect(() => {
    setShouldDestroyGraph(true)
    updateGraph()
  }, [openSidebarWidth, containerWidth])
  return (
    <div style={isSubmission ? {border: '1px solid #ccc', padding: '0.5rem', borderRadius:'6px'} : {}}>
      <div className={classes.graphContainer} style={{width:containerWidth, height: poll.height + 'vh'}}>
          {graph}
      </div>
      <VoteButton
        types={poll.types.filter(type => type.length)}
        onVote={index => {
          if (isSubmission) {
            updateSubmissionPoll(poll, index)
          } else {
            updateRealPoll(messageID, index)
          }
          setGraph(null)
        }}
        canVote={isSubmission || thisUserId && !poll.voters.includes(thisUserId)}
      />
      {messageText}
    </div>

  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateRealPoll: (messageID, index) => {
      dispatch(actions.updateMessagePoll({messageID, index}))
    },
    updateSubmissionPoll: (poll, index) => {
      const clone = Object.assign({}, poll)
      clone.values[index] += 1
      dispatch(actions.changeSubmissionPoll({poll:clone}))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isReply, isSubmission, messageID } = ownProps
  let messageText
  let openSidebarWidth = 0
  if (state.sidebar.mainExpanded) {
    openSidebarWidth += parseInt(constants.STYLES.DRAWER_WIDTH, 10)
    if (state.sidebar.subExpanded) {
      openSidebarWidth += parseInt(constants.STYLES.INNER_DRAWER_WIDTH, 10)
    }
  }
  let poll
  if (isSubmission || !state.messages.data[messageID]) {
    poll = state.submission.content.poll
    if (isReply) {
      messageText = ''
      // - half view width because the preview message is in half the view 
      openSidebarWidth = ' -50vw ' + openSidebarWidth
    } else {
      messageText = state.submission.content.message
    }
  } else {
    poll = selectors.getMessageByID(state, messageID).content.poll
  }

  openSidebarWidth += 'rem'

  const thisUserId = state.members.thisUser
  return { poll, messageText, openSidebarWidth, thisUserId }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedPoll)
