import {
  // CREATE_MESSAGE_SUCCESS,
  // UPDATE_MESSAGE_POLL_SUCCESS,
  EDIT_MESSAGE_SUCCESS,
  ON_MESSAGE_VIEW,
  ON_MESSAGE_REPLY,
  ON_MESSAGE_SELECT,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  MESSAGE_REPLY,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES,
  DELETE_MESSAGE,
  POLL_VOTE_REQUEST,
  GET_UPLOAD_URL,
  GET_DOWNLOAD_URL,
  GET_DOWNLOAD_URL_REQUEST,
  UPLOADING_FILE,
  UPLOADED_FILE,
  FILE_UPLOAD_ERROR,
  CHANGE_SHOW_EDIT_MESSAGE
} from './types'
import { sendMessage } from '../chat/actions'
import uuid from 'uuid/v1'
import axios from 'axios'
import selectors from '../selectors'
import actions from '../../lib/actions'
import constants from '../../lib/constants'
import { Logger } from '../../lib/utilities'
import { SUBMISSION_PUBLISHED_CHANGED } from '../submission/types'

export function onMessageReply(payload) {
  return dispatch => {
    dispatch({type: ON_MESSAGE_REPLY, payload})
    const message = Object.assign({}, payload.message)
    message.options = Object.assign({}, payload.message.options, {replied: payload.member.name})
    dispatch(sendMessage({
      type: MESSAGE_REPLY,
      data: { messageData: message, member: payload.member}
    }))
  }
}

export function onMessageView(payload) {
  return { type: ON_MESSAGE_VIEW, payload}
}

export function createMessage(payload) {
  return dispatch => {
    // Logger.log("create message action:", payload)
    // timeposted will be overwritten on the server for actual time
    payload.message.timePosted = new Date().getTime()
    payload.message.to = payload.to
    payload.message.memberID = payload.member.id
    if (!payload.message.id) {
      payload.message.id = uuid()
    }
    dispatch({type: CREATE_MESSAGE_REQUEST, payload})
    const clone = Object.assign({}, payload.message)
    delete clone.pending
    dispatch(sendMessage({
      type: CREATE_MESSAGE,
      data: { messageData: clone }
    }))
  }
}

export const getUploadFileUrl = payload => {
  return dispatch => {
    dispatch({type: GET_UPLOAD_URL, payload})
    dispatch(sendMessage({
      type: GET_UPLOAD_URL,
      data: { assetId: payload.assetId, contentType: payload.contentType, messageId: payload.messageId }
    }))
  }
}

export const uploadFile = payload => {
  return (dispatch, getState) => {
    dispatch({type: UPLOADING_FILE, payload})
    // put the attachment to the url provided
    // Logger.log("payload upload file action:", payload)
    const { assetId, messageId, url } = payload
    const state = getState()
    const file = state.messages.data[messageId].attachments[assetId].attachment
    const options = {
      headers: { 'Content-Type': payload.contentType, 'x-amz-acl': 'bucket-owner-full-control'},
      // @todo
      // onUploadProgress: progressEvent => {
      //   if (progressEvent.loaded === progressEvent.total) {
      //     // file is completely uploaded
      //   }
      // }
    }
    axios.put(url, file, options)
      .then(() => {
        dispatch({type: UPLOADED_FILE, payload})
      }).catch(err => {
        dispatch({type: FILE_UPLOAD_ERROR, err})
        Logger.error('file upload error:', err)
      })
  }
}

export function getDownloadFileUrl(payload) {
  return dispatch => {
    dispatch({type: GET_DOWNLOAD_URL_REQUEST, payload})
    dispatch(sendMessage({
      type: GET_DOWNLOAD_URL,
      data: { assetId: payload.assetId, messageId: payload.messageId, fileName: payload.fileName }
    }))
  }
}

export function getMessages(payload) {
  return dispatch => {
    dispatch({type: GET_MESSAGES_REQUEST, payload})
    dispatch(sendMessage({
      type: GET_MESSAGES,
      data: { userId: payload.userId, email: payload.email && payload.email.toLowerCase(), sub: payload.sub }
    }))
  }
}

export function updateMessagePoll(payload) {
  // Logger.log("poll vote:", payload)
  return dispatch => {
    dispatch({ type: POLL_VOTE_REQUEST, payload })
    const messageId = payload.messageID
    const { index } = payload
    dispatch(sendMessage({
      type: POLL_VOTE_REQUEST,
      data: { messageId, index }
    }))
  }
  // send websocket to vote on a poll
  // return { type: UPDATE_MESSAGE_POLL_SUCCESS, payload}
}

export function editMessage(payload) {
  return dispatch => {
    payload.message.pending = true
    dispatch({ type: EDIT_MESSAGE_SUCCESS, payload})
    delete payload.message.pending
    dispatch(sendMessage({
      type: UPDATE_MESSAGE,
      data: { messageData: payload.message }
    }))
  }
}

export function deleteMessage(payload) {
  return dispatch => {
    payload.message.options.deleted = true
    dispatch({type: UPDATE_MESSAGE, payload})
    dispatch(sendMessage({
      type: DELETE_MESSAGE,
      data: { messageData: payload.message }
    }))
  }
}

export const changeShowEdit = payload => {
  return { type: CHANGE_SHOW_EDIT_MESSAGE, payload}
}

export function selectMessage(payload) {
  return (dispatch, getState) => {
    // check that this message should be selected and if a room change should occur
    let shouldSelect = true
    let changeRoom = false
    const { messageID } = payload
    const state = getState()
    const message = selectors.getMessageByID(state, messageID)
    const thisUser = selectors.getCurrentMember(state)
    const selectedUser = selectors.getMemberByID(state, message.memberID)
    const selectedUserType = selectedUser.type
    /*
      check that message can be selected
        - not a poll
        - not posted by thisUser
    */
    if (
        !thisUser ||
        message.content.poll ||
        message.memberID === thisUser.userId
      ) {
      shouldSelect = false
    }

    /*
      chat if should change room
        - message posted to Public, change to a private chat with that user
        - message posted to Moderator, change to a private chat with that user, if the poster was not a moderator
        - message posted to thisUser's id, change to a private chat with that user
      - ^^ and thisUser is not already in the room to change to 
    */
    if (shouldSelect) {
      if (
        message.to === constants.CHAT.GENERAL
      ) {
        changeRoom = message.memberID
      } else if (
        message.to === constants.CHAT.MODERATOR &&
        selectedUserType !== constants.MEMBER_TYPES.MODERATOR
      ) {
        changeRoom = message.memberID
      } else if (
        message.to === thisUser.userId
      ) {
        changeRoom = message.memberID
      }
    }

    if (shouldSelect) {
      if (payload.messageID === state.messages.selectedMessage) {
        // message will be unselected so it can't be replied to, remove replies from submission
        dispatch({type: SUBMISSION_PUBLISHED_CHANGED, payload: {published: undefined}})
      }
      dispatch({type: ON_MESSAGE_SELECT, payload})
    }
    if (changeRoom && changeRoom !== thisUser.currentRoom) {
      let roomName = changeRoom
      if (Object.keys(constants.CHAT).map(key => constants.CHAT[key]).includes(changeRoom)) {
        // changeRoom to a group chat ie: Public => Public Chat
        dispatch(actions.changeMainTab({mainTab: changeRoom}))
        dispatch(actions.changeSubTab({subTab: changeRoom}))
        roomName = changeRoom + ' Chat'
      } else {
        switch (selectedUserType) {
          case constants.MEMBER_TYPES.MODERATOR:
            dispatch(actions.changeMainTab({mainTab: constants.CHAT.MODERATOR}))
            break
          case constants.MEMBER_TYPES.MEMBER:
            dispatch(actions.changeMainTab({mainTab: constants.CHAT.GENERAL}))
            break
        }
        dispatch(actions.changeSubTab({subTab: changeRoom}))
      }
      dispatch(actions.changeRoom({
        room: changeRoom,
        lastIn: thisUser.lastIn[changeRoom],
        previousRoom: thisUser.currentRoom,
        thisUser,
        roomName: roomName
      }))
    }
  }
}
