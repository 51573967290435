import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import selectors from '../../../store/selectors'
import constants from '../../../lib/constants'
import { Button } from '@material-ui/core'
import actions from '../../../lib/actions'

const useStyles = makeStyles(() => ({
  roomHeader: {
    color: '#4a4a4a',
    fontSize: '1.4rem',
    marginTop: '-0.7rem',
    maxHeight: '3rem',
    height: '5vh',
    minHeight: '2rem',
    display: 'inline-flex',
  },
  roomHeaderButton: {
    marginLeft: '1rem',
    maxHeight: '2rem',
  },
}))

export const UnconnectedHeader = (props) => {
  const { headerText, room, setAwaitTime, lastMessageTime, isAwaitingReply, notLoaded } = props
  const classes = useStyles()

  const showAwaitingButton = !constants.GROUP_ROOMS.includes(room) && !!lastMessageTime
  const handleSetAwaiting = () => {
    setAwaitTime(isAwaitingReply ? new Date().getTime() : lastMessageTime, room)
  }

  let buttonText = isAwaitingReply ? 'Mark as Read': 'Mark as UnRead'
  if (notLoaded) return <div className={classes.roomHeader}>
    Loading ...
  </div>
  return (
    <div className={classes.roomHeader}>
      <span>{headerText}</span>
      {showAwaitingButton ? <Button
        onClick={handleSetAwaiting}
        className={classes.roomHeaderButton}
        variant='contained'
        size='small'
        color='primary'
      >
        {buttonText}
      </Button> : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  const thisUser = selectors.getCurrentMember(state)

  const room = thisUser.currentRoom
  let headerText = 'Public Chat'
  if (!thisUser || thisUser && !thisUser.currentRoom) return { headerText, room, lastMessageTime: 0, notLoaded: true}

  const isGroupChat = Object.keys(constants.CHAT)
    .map((key) => constants.CHAT[key])
    .includes(thisUser.currentRoom)

  const otherUser = selectors.getMemberByID(state, thisUser.currentRoom)
  if (isGroupChat) {
    headerText =
      thisUser.currentRoom !== constants.CHAT.POLL
        ? thisUser.currentRoom + ' Chat'
        : 'Create a Poll'
  } else if (otherUser) {
    if (otherUser.userId === thisUser.userId) {
      headerText = 'Note to Self'
    } else if (otherUser.type === constants.MEMBER_TYPES.MODERATOR) {
      headerText = otherUser.name + ' (Moderator)'
    } else {
      headerText = otherUser.name + ' (Visitor)'
    }
  }
  const lastMessage = selectors.getLastRoomMessage(state, thisUser.currentRoom)
  const isAwaitingReply = selectors.getRoomIsAwaitingReply(state, thisUser.currentRoom)
  const lastMessageTime = lastMessage && lastMessage.timePosted
  return { headerText, room, lastMessageTime, isAwaitingReply }
}

const mapDispatchToProps = dispatch => ({
  setAwaitTime: (awaitTime, room) => dispatch(actions.setAwaitTime({awaitTime, userId: room}))
})

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedHeader)
