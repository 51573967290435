import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  errorContainer: {
    position:'absolute',
    left: 0,
    right: 0,
    top: '5rem',
    bottom: 0,
    zIndex: 9999999,
    backgroundColor: 'white'
  },
  error: {
    padding: '3rem',
    fontSize: 'x-large',
    backgroundColor: 'orange',
    textAlign: 'center',
  },
  nonBlockingContainer: {
    top: 'auto',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)'
  },
  nonBlockingError: {
    padding: '0.5rem',
    fontSize: 'large',
    borderRadius: '5px'
  }
}))

const ErrorView = props => {
  const {nonBlocking} = props
  const classes = useStyles()
  return (
    <div className={clsx(classes.errorContainer, nonBlocking && classes.nonBlockingContainer)}>
      <div className={clsx(classes.error, nonBlocking && classes.nonBlockingError)}>
        { props.children }
      </div>
    </div>
  )
}

export default ErrorView
