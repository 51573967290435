import constants from '../lib/constants'

const getCurrentMember = state => {
  if (state.members.thisUser !== null) {
    return state.members.data[state.members.thisUser]
  }
  return undefined
}

const getMessageByID = (state, id) => {
  return state.messages.data[id]
}

const getMemberByID = (state, id) => {
  return state.members.data[id]
}

const getMemberIDsInRoom = (state, room) => {
  const memberKeys = Object.keys(state.members.data).filter(email => state.members.data[email].currentRoom === room)
  return memberKeys
}

const getMemberIDsInGroup = (state, tab = state.sidebar.subTab) => {
  const memberKeys = Object.keys(state.members.data)
  switch (tab) {
    case constants.CHAT.GENERAL:
      //@TODO: add in email verification check or verified moderator check
      //@TODO: email verified check was removed as it wasn't being provided always (possibly race condition ?)
      return memberKeys.filter(key => state.members.data[key].type === constants.MEMBER_TYPES.MEMBER)
    case constants.CHAT.MODERATOR:
      return memberKeys.filter(key => {
        const {type} = state.members.data[key]
        return (type === constants.MEMBER_TYPES.MODERATOR || type === constants.MEMBER_TYPES.HOST)
      })
    default:
      return memberKeys
  }
}

const getCurrentRoom = state => {
  const user = getCurrentMember(state)
  return user ? user.currentRoom : undefined
}

const getEventData = state => {
  return state.chat.eventData
}

const getMessageKeys = state => {
  return Object.keys(state.messages.data)
}

const getRoomMessages = (state, room) => {
  if (room === false){
    return []
  }
  const messages = getMessageKeys(state).map(key => getMessageByID(state, key)).filter(message => {
    // remove all messages that have been created by users that don't exist in the chat
    // possibly users will be able to be deleted in the future, but if during testing a user is deleted from the DB
    // their messages should not be viewable (alternatively a generic deleted type user could replace them)
    return !!getMemberByID(state, message.memberID) && (!!getMemberByID(state, message.to) || message.to === constants.CHAT.MODERATOR || message.to === constants.CHAT.GENERAL)
  })
  const thisUser = getCurrentMember(state)
  if (!thisUser || thisUser.type === constants.MEMBER_TYPES.MEMBER) {
    // users are only given pertinent messages
    const undeletedMessages = messages.filter(message => {
      if (message.options.deleted){
        return false
      }
      return true
    })
    return undeletedMessages
  }
  const inGroupChat = Object.keys(constants.CHAT).map(key=>constants.CHAT[key]).includes(room)
  const roomMessages = messages.filter(message =>{
    if (message.options && message.options.deleted) {
      return false
    }
    const to = message.to
    const from = message.memberID
    if (inGroupChat) {
      // remove user messages to moderator room
      if (room === constants.CHAT.MODERATOR && getMemberByID(state, from).type === constants.MEMBER_TYPES.MEMBER) {
        return false
      }
      return to === room
    } else {
      const otherUser = getMemberByID(state, room)
      if (!otherUser) {
        return to === room
      }
      // direct messages between moderators
      if (otherUser.type && otherUser.type === constants.MEMBER_TYPES.MODERATOR && otherUser.email_verified) {
        return (to === thisUser.userId && from === otherUser.userId) || (to === otherUser.userId && from === thisUser.userId)
      } else {
        // also include messages to moderators from this user
        return ((to === thisUser.userId && from === otherUser.userId) || (to === otherUser.userId && from === thisUser.userId) ||
          (to === constants.CHAT.MODERATOR && from === otherUser.userId && otherUser.type === constants.MEMBER_TYPES.MEMBER))
      }
    }
  })
  return roomMessages
}

const getCurrentRoomMessages = state => {
  const currentRoom = getCurrentRoom(state)
  if (!currentRoom) {
    return getRoomMessages(state, constants.CHAT.GENERAL)
  }
  if (
    state.sidebar.mainTab === constants.CHAT.POLL &&
    Object.keys(state.messages.data).includes(getCurrentRoom(state))
  ) {
    let message = getMessageByID(state, Object.keys(state.messages.data).filter(key => key === state.sidebar.subTab))
    if (message) {
      message = [message]
    } else {
      message = []
    }
    return message
  }
  return getRoomMessages(state, currentRoom)
}

/**
 * 
 * @returns {Object} the options object for that user
 * @param {Object} state the store state
 * @param {string} userId the user id to get the options for
 */
const getMemberOptions = (state, userId) => {
  const user = state.members.data[userId]
  if (!user) return {}
  return user.options
}

/**
 * @returns the last message posted in the room or null
 * 
 * @param {Objec} state current store state
 * @param {string} room either a userId or a group chat room name
 * @param {Object[]?} roomMessages optional roomMessages to use, defaults to the selectors.getRoomMessages call
 */
const getLastRoomMessage = (state, room, roomMessages = getRoomMessages(state, room)) => {
  if (roomMessages.length === 0) return null
  let lastMessage = roomMessages[0]
  let lastMessageTime = lastMessage.timePosted
  for (let i = 1; i < roomMessages.length; i++) {
    if (roomMessages[i].timePosted > lastMessageTime) {
      lastMessage = roomMessages[i]
      lastMessageTime = roomMessages[i].timePosted
    }
  }
  return lastMessage
}

/**
 * @summary check the messages for the room and the room options and return whether the room
 *    would be waiting for a moderator to reply to their message (last post was by room owner)
 * @returns {boolean} whether the given room was replied to by someone besides the room owner
 *  or if the room is marked as awaiting reply and the room is not a group room
 *  or if the last message has a public reply
 * @param {Object} state the store state
 * @param {string} room the room name to check
 * @param {Object[]?} roomMessages the roomMessages if they are available, defaults to selectors.getRoomMessages
 */
const getRoomAwaitTime = (state, room, roomMessages = getRoomMessages(state, room)) => {
  if (roomMessages.length === 0) return 0
  if (!constants.GROUP_ROOMS.includes(room)) {
    const userRoomOptions = getMemberOptions(state, room)
    const awaitTime = userRoomOptions.awaitTime
    return awaitTime
  }
  return 0
}

/**
 * 
 * @param {Object} state the redux store state
 * @param {string} room the room name or a userId to check
 * 
 * @returns {boolean} whether the last message was posted by the room owner or room was marked as awaiting
 *  (awaitTime <= lastMessage)
 */
const getRoomIsAwaitingReply = (state, room) => {
  if (constants.GROUP_ROOMS.includes(room)) {
    return false
  }
  const userId = room
  const roomMessages = getRoomMessages(state, room)
  const awaitTime = getRoomAwaitTime(state, room, roomMessages)
  const lastMessage = getLastRoomMessage(state, room, roomMessages)
  if (!lastMessage) return false
  const repliedTo = lastMessage && lastMessage.memberID !== userId || Object.keys(state.messages.data).some(messageId => {
    const message = state.messages.data[messageId]
    return message.published && message.published.messageId === lastMessage.id
  })
  if (awaitTime) {
    if ((awaitTime > lastMessage.timePosted || repliedTo) && awaitTime !== lastMessage.timePosted) {
      // marked as read or was replied to
      return false
    } else {
      return true
    }
  }
  return !repliedTo
}

const getMainTabMessageCountAndHasNew = (state, room) => {
  let count = 0
  let hasNew = false
  switch (room) {
    case constants.CHAT.GENERAL:
    case constants.CHAT.MODERATOR: {
      count += getRoomMessages(state, room).length
      if (getUnseenRoomMessages(state, room).length) {
        hasNew = true
      }
      const members = getMemberIDsInGroup(state, room).map(memberId => getMemberByID(state, memberId))
      members.forEach(member => {
        count += getRoomMessages(state, member.userId).length
        if (getUnseenRoomMessages(state, member.userId).length) {
        hasNew = true
        }
      })
      break
    }
  }
  return [count, hasNew]
}

const getUnseenRoomMessages = (state, room) => {
  const roomMessages = getRoomMessages(state, room)
  return roomMessages.filter(message => {
    const thisUser = getCurrentMember(state)
    const messageCreator = getMemberByID(state, message.memberID)
    if (!messageCreator) return false
    if (!thisUser || !thisUser.lastIn || !thisUser.lastIn[room]) return true
    const seen = message.seen
    const currentRoom = thisUser.currentRoom
    const lastInRoom = thisUser.lastIn[room]
    // message is unseen if it has seen:false and was posted after thisUser was last in the room
    return !seen && lastInRoom < message.timePosted && currentRoom !== room
  })
}

const getCurrentMainTab = state => {
  return state.sidebar.mainTab
}

const getChatSettings = state => {
  return state.chat.eventData.chat
}

const getThisMemberType = state => {
  const thisUser = getCurrentMember(state)
  if (!thisUser || !thisUser.userId) {
    return constants.MEMBER_TYPES.MEMBER
  }
  return getMemberTypeByID(state, thisUser.userId)
}

const getMemberTypeByID = (state, id) => {
  const user = getMemberByID(state, id)
  let type
  if (user && user.type === constants.MEMBER_TYPES.MODERATOR && user.email_verified) {
    type = constants.MEMBER_TYPES.MODERATOR
  } else {
    type = constants.MEMBER_TYPES.MEMBER
  }
  return type
}

/**
 * @returns {boolean} whether the selected subtab relates to moderators
 * @param {Object} state the current redux state
 */
const getIsInModeratorView = state => {
  const { mainTab, subTab } = state.sidebar
  if (mainTab === constants.CHAT.MODERATOR) {
    if (subTab === constants.CHAT.MODERATOR) {
      return true
    }
    // subTab is a userId for a user's room
    const subTabRoomUserType = getMemberTypeByID(state, subTab)
    if (subTabRoomUserType === constants.MEMBER_TYPES.MODERATOR || subTabRoomUserType === constants.MEMBER_TYPES.HOST) {
      return true
    }
  }
  return false
}

//init: https://invintus-client-images.s3.amazonaws.com/1000000000/877b5183159f9b846f407c4374678a45e5ec493a.jpg
//goal: https://invintus-client-media.s3.amazonaws.com/1000000000/877b5183159f9b846f407c4374678a45e5ec493a.mp4
const getEventVideoSrc = state => {
  // return null if there is no video associated with the event
  if (!state.chat.eventData.videoThumbnail) return null
  let src = state.chat.eventData.videoThumbnail.slice(0, -3) + 'mp4'
  src = src.replace('invintus-client-images', 'invintus-client-media')
  return src
}

const getPastPolls = state => {
  return getMessageKeys(state).map(key => {
    return getMessageByID(state, key)
  }).filter(message => {
    return !!message.content.poll
  })
}

export default {

  getCurrentMember,

  getMessageByID,

  getEventData,

  getCurrentRoomMessages,

  getCurrentRoom,

  getMemberByID,

  getMemberIDsInGroup,

  getMemberIDsInRoom,

  getUnseenRoomMessages,

  getRoomIsAwaitingReply,

  getRoomAwaitTime,

  getMemberOptions,

  getChatSettings,

  getThisMemberType,

  getIsInModeratorView,

  getLastRoomMessage,

  // getMemberTypeByID, // used in this file

  getRoomMessages,

  getCurrentMainTab,

  getMainTabMessageCountAndHasNew,

  getEventVideoSrc,

  getPastPolls

}
