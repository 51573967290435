import {
  SHRINK_SIDEBAR,
  EXPAND_SIDEBAR,
  CHANGE_MAIN_TAB,
  CHANGE_SUB_TAB,
  SHOW_SIDEBAR_MOBILE,
  HIDE_SIDEBAR_MOBILE,
  SHRINK_MAIN_SIDEBAR,
  SHRINK_SUB_SIDEBAR,
  EXPAND_MAIN_SIDEBAR,
  EXPAND_SUB_SIDEBAR,
  CHANGE_SIDEBAR_WIDTH,
  CHANGE_SIDEBAR_SEARCH
} from './types'

export const changeSearch = payload => {
  return { type: CHANGE_SIDEBAR_SEARCH, payload}
}

export function shrinkSidebar() {
  return {type: SHRINK_SIDEBAR}
}

export function expandSidebar() {
  return {type: EXPAND_SIDEBAR}
}

export function shrinkSubSidebar() {
  return {type: SHRINK_SUB_SIDEBAR}
}

export function expandSubSidebar() {
  return {type: EXPAND_SUB_SIDEBAR}
}

export function shrinkMainSidebar() {
  return {type: SHRINK_MAIN_SIDEBAR}
}

export function expandMainSidebar() {
  return {type: EXPAND_MAIN_SIDEBAR}
}

export function showSidebarMobile() {
  return {type: SHOW_SIDEBAR_MOBILE}
}

export function hideSidebarMobile() {
  return {type: HIDE_SIDEBAR_MOBILE}
}

export function changeMainTab(payload) {
  return dispatch => {
    dispatch({type: CHANGE_MAIN_TAB, payload})
  } 
}

export function changeSubTab(payload) {
  return {type: CHANGE_SUB_TAB, payload}
}

export function changeSidebarWidth(payload) {
  return {type: CHANGE_SIDEBAR_WIDTH, payload}
}
