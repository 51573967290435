import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const Loading = () => {
  return (
    <div style={{position: 'relative', left: '50%', right: '50%', top: '50%', bottom: '50%', backgroundColor: '#0000', zIndex:1201, width: 'min-content'}}>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  )
}

export default Loading
