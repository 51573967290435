import uuid from 'uuid/v4'

import {
  SUBMISSION_ATTACHMENTS_CHANGE,
  SUBMISSION_PINNED_CHANGE,
  SUBMISSION_POLL_CHANGE,
  SUBMISSION_TEXT_CHANGE,
  SUBMISSION_SHOW_POLL_CHANGE,
  START_PUBLISH_REPLY,
  START_SUBMIT_MESSAGE,
  SUBMISSION_SHOW_REPLY_CHANGE,
  SUBMISSION_PUBLISHED_CHANGED,
  SUBMISSION_PUBLISHED_MESSAGE_CHANGE,
  SUBMISSION_PUBLISHED_NAME_CHANGE,
  RESET_SUBMISSION_POLL,
  SUBMISSION_GET_UPLOAD_URL,
  SUBMISSION_UPLOADED_FILE,
  SUBMISSION_SAVED_CHANGE,
  LOAD_MESSAGE_INTO_SUBMISSION,
  SUBMISSION_TIMED_OUT,
  CLEAR_SUBMISSION_ERROR
} from './types'
import { MESSAGE_CREATED } from '../messages/types'
import { CHANGE_SUB_TAB, CHANGE_MAIN_TAB } from '../sidebar/types'
import constants from '../../lib/constants'
import { Logger } from '../../lib/utilities'
import cloneDeep from 'lodash.clonedeep'
import { INTERNAL_SERVER_ERROR } from '../chat/types'

const getInitialState = () => (
  {
    content: {message:''},
    options: {},
    id: uuid(),

    showPoll: false,
    showReply: false,
    pending: false
  }
)

const reducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case CHANGE_SUB_TAB: {
      const clone = Object.assign({}, state)
      if (clone.showReply) {
        clone.showReply = false
      }
      return clone
    }
    case MESSAGE_CREATED: {
      const clone = Object.assign({}, state)
      const message = action.payload
      if (clone.id === message.id) {
        // this message was successfully sent
        return getInitialState()
      }
      break
    }
    case SUBMISSION_TEXT_CHANGE: {
      return {
        ...state,
        content: {
          ...state.content,
          message: action.payload.text
        }
      }
    }
    case LOAD_MESSAGE_INTO_SUBMISSION: {
      const message = cloneDeep(action.payload.message)
      if (!message) {
        // this shouldn't happen...
        Logger.warn('no message to load:', action)
        return state
      }
      message.id = state.id
      message.options.saved = false
      delete message.timePosted
      delete message.to
      delete message.seen
      delete message.memberID
      return {...state, ...message}
    }
    case CLEAR_SUBMISSION_ERROR: {
      const clone = {...state}
      delete clone.error
      return {
        ...clone
      }
    }
    case SUBMISSION_SAVED_CHANGE: {
      const clone = Object.assign({}, state)
      clone.options.saved = action.payload.saved
      return clone
    }
    case SUBMISSION_PINNED_CHANGE: {
      const clone = Object.assign({}, state)
      clone.options.pinned = action.payload.pinned
      return clone
    }
    case SUBMISSION_ATTACHMENTS_CHANGE: {
      const clone = Object.assign({}, state)
      clone.content.attachments = action.payload.attachments
      return clone
    }
    case SUBMISSION_POLL_CHANGE: {
      const clone = Object.assign({}, state)
      const poll = Object.assign({}, clone.content.poll, action.payload.poll)
      if (!poll.graphType) {
        poll.graphType = 'Vertical'
      }
      if (!poll.types) {
        poll.types = []
      }
      if (poll.label === undefined) {
        poll.label = 'label'
      }
      if (!poll.colors) {
        poll.colors = ['#ff6384']
      }
      if (!poll.values || poll.values.length !== poll.types.length) {
        poll.values = new Array(poll.types.length).fill(0)
      }
      if (poll.height === undefined) {
        poll.height = constants.STYLES.DEFAULT_POLL_HEIGHT
      }
      poll.height = parseInt(poll.height, 10) || 0
      poll.voters = []
      clone.content.poll = poll
      return clone
    }
    case SUBMISSION_SHOW_POLL_CHANGE: {
      const clone = Object.assign({}, state)
      clone.showPoll = action.payload.showPoll
      delete clone.error
      return clone
    }
    case SUBMISSION_SHOW_REPLY_CHANGE: {
      const clone = Object.assign({}, state)
      clone.showReply = action.payload.showReply
      delete clone.error
      return clone
    }
    case START_SUBMIT_MESSAGE: {
      const clone = Object.assign({}, state)
      clone.pending = true
      delete clone.error
      return clone
    }
    case INTERNAL_SERVER_ERROR: {
      if (state.pending) {
        return {
          ...state,
          pending: false,
          error: 'Message may have failed to send: Server error'
        }
      }
      return state
    }
    case SUBMISSION_TIMED_OUT: {
      return {
        ...state,
        pending: false,
        error: 'Message may have failed to send: Timed out'
      }
    }
    case START_PUBLISH_REPLY: {
      const clone = Object.assign({}, state)
      clone.pending = true
      return clone
    }

    case SUBMISSION_PUBLISHED_NAME_CHANGE: {
      const clone = Object.assign({}, state)
      if (!clone.published) {
        clone.published = {
          name: action.payload.name,
          content: {
            message: action.payload.selectedMessage.content.message
          }
        }
      }
      clone.published.name = action.payload.name
      return clone
    }
    case SUBMISSION_PUBLISHED_MESSAGE_CHANGE: {
      const clone = Object.assign({}, state)
      if (!clone.published) {
        clone.published = {
          name: action.payload.selectedMessage.name,
          content: {
            message: action.payload.message
          }
        }
      }
      clone.published.content.message = action.payload.message
      return clone
    }
    case SUBMISSION_PUBLISHED_CHANGED: {
      const clone = Object.assign({}, state)
      clone.published = action.payload.published
      if (clone.published === undefined) {
        clone.showReply = false
      }
      return clone
    }
    case RESET_SUBMISSION_POLL: {
      const clone = Object.assign({}, state)
      delete clone.content.poll
      return clone
    }
    case SUBMISSION_GET_UPLOAD_URL: {
      const clone = Object.assign({}, state)
      for(let i = 0; i < clone.content.attachments.length; i++) {
        if (clone.content.attachments[i].name === action.payload.name) {
          clone.content.attachments[i].assetId = action.payload.assetId
          clone.content.attachments[i].fileName = action.payload.name
          clone.content.attachments[i].pending = true
          clone.content.attachments[i].uploaded = false
          break
        }
      }
      return clone
    }
    case SUBMISSION_UPLOADED_FILE: {
      const clone = Object.assign({}, state)
      for(let i = 0; i < clone.content.attachments.length; i++) {
        if (clone.content.attachments[i].assetId === action.payload.assetId) {
          clone.content.attachments[i].pending = false
          clone.content.attachments[i].fileName = action.payload.fileName
          clone.content.attachments[i].uploaded = true
          break
        }
      }
      return clone
    }
  }
  return state
}

export default reducer
