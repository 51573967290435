import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Heading from './heading'
import actions from '../../lib/actions'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    height: '100%',
    // this width is still constrained by the drawer width
    width: '100%',
    paddingTop: '4rem',
    overflow: 'hidden',
    border: (props) => props.border || '2px solid #b3b3bc',
    borderLeft: 0,
    borderTop: 0,
    marginRight: (props) => props.marginRight || '',
    paddingLeft: (props) => props.paddingLeft || '',
    paddingRight: (props) => props.paddingRight || '',
  },
  container: {
    backgroundColor: 'inherit',
  },
  sidebarItemContainer: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 7rem)',
  },
}))

export const UnconnectedSidebar = (props) => {
  const { header, styles, innerRef, type } = props
  const classes = useStyles(styles)
  return (
    <div className={classes.root}>
      <Grid container justify='center' alignItems='center' className={classes.container}>
        <Grid item xs={12}>
          <Heading
            text={header}
            type={type}
          />
          <div className={classes.sidebarItemContainer} ref={innerRef}>
            {props.children}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  const width = state.sidebar.width
  return { width }
}

export default connect(mapStateToProps)(UnconnectedSidebar)
