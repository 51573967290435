import {
  CHAT_RECIEVED_MESSAGE,
  CHAT_ON_CLOSE,
  CHAT_SENT_CLOSE,
  CHAT_SEND_MESSAGE,
  CHAT_ON_CONNECTION,
  CHAT_ATTEMPT_CONNECTION,
  CHAT_CONNECTED_MEMBERS,
  CHAT_MEMBERS_RESPONSE,
  CHAT_ON_ERROR,
  GET_USER_RESPONSE,
  INTERNAL_SERVER_ERROR,
  RECONNECT_USERS_TO_CHAT,
  UPDATE_CHAT_SETTINGS,
  CHAT_EVENT_DATA,
  HEART_BEAT,
  EVENT_CONNECT_REQUEST,
  EVENT_CONNECT_RESPONSE,
  EVENT_CONNECT_ERROR,
} from './types'
import { GET_MEMBERS_RESPONSE, NEW_CHAT_USER, USER_UPDATE } from '../members/types'
import { MESSAGE_CREATED, MESSAGE_UPDATED, GET_MESSAGES_RESPONSE, ON_MESSAGE_REPLY, SET_MESSAGE_ATTACHMENT_URL, GET_MESSAGES_REQUEST, GET_MESSAGES } from '../messages/types'
import actions from '../../lib/actions'
import constants from '../../lib/constants'
import { Logger, isDevEnv, getRestEndpoint } from '../../lib/utilities'

export const reconnectAllUsers = () => {
  return dispatch => {
    dispatch(sendMessage({type: RECONNECT_USERS_TO_CHAT, data:{}}))
    // dispatch(disconnectFromChat())
  }
}

export const updateChatSettings = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const eventId = state.chat.eventData.eventID
    const clientId = state.chat.eventData.clientID
    const settings = payload.chatSettings
    const data = {type: 'updateChatSettings', settings, eventId, clientId}
    // eslint-disable-next-line no-undef
    let xhr = new XMLHttpRequest()
    xhr.open('POST', getRestEndpoint() + 'updateChatSettings')
    xhr.send(JSON.stringify({data}))
    xhr.onload = () => {
      if (xhr.status === 200) {
        const settings = JSON.parse(xhr.responseText).chat
        dispatch(sendMessage({type: UPDATE_CHAT_SETTINGS, data:{chatSettings: settings}}))
      }
    }
  }
}

/**
 * 
 * @param {object} payload sent to lambda function to process events that use database or send messages to other websocket clients
 * payload should be in the form:
 * {
 *    {string?} action: optional lambda function name to use
 *    {string} type: the name of the function in the lambda method
 *    {object} data: the object of data to use required by the lambda function type used
 * }
 * @example
 * {
 *    action: null,
 *    type: 'CREATE_USER',
 *    data: userData // userData is like { userData: ...}
 * }
 */
export const sendMessage = payload => {
  return (dispatch, getState) => {
    const state = getState()
    const websocket = state.chat.websocket
    const eventID = state.chat.eventData.eventID
    if (!websocket && eventID) {
      connectToChat({eventID})
      return
    }
    if (!websocket) {
      Logger.error('no websocket', payload)
      return
    }
    const message = JSON.stringify({'action':payload.action ? payload.action : 'crud', type:payload.type,  data:{...payload.data}})
    websocket.send(message)
    dispatch({type: CHAT_SEND_MESSAGE, payload})
  }
}

export const disconnectFromChat = () => {
  return (dispatch, getState) => {
    const state = getState()
    state.chat.websocket.close()
    dispatch({type: CHAT_SENT_CLOSE})
  }
}

export const getUser = payload => {
  let retry
  return (dispatch, getState) => {
    // dispatch({type: GET_USER, payload})
    dispatch(sendMessage({type:'CREATE_USER', data:payload.userData}))
    if (retry) {
      clearInterval(retry)
    }
    const state = getState()
    retry = setInterval(() => {
      if (state.members.data[state.members.thisUser] && state.members.data[state.members.thisUser].from !== constants.FROM.DB) {
        dispatch(sendMessage({type:'CREATE_USER', data:payload.userData}))
      }
    }, 5000)

  }
}

export function connectToEvent(payload) {
  const {eventID, clientID, heartBeat} = payload
  Logger.log('connect to event:', payload)
  return dispatch => {
    if (!heartBeat) {
      dispatch({type: EVENT_CONNECT_REQUEST, payload})
    }
    // this call should be done on the server
    const handleError = message => {
      dispatch({type: EVENT_CONNECT_ERROR, response:message})
    }
    // eslint-disable-next-line no-undef
    let xhr = new XMLHttpRequest()
    xhr.open('POST', getRestEndpoint() + 'getEventDetails')
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (xhr.responseText === 'null') {
          handleError({error:'No matching event created for the given clientID and eventID'})
        } else {
          dispatch({type: EVENT_CONNECT_RESPONSE, response:JSON.parse(xhr.responseText)})
        }
      } else {
        handleError(xhr.responseText)
      }
    }
    xhr.onerror = () => {
      handleError(xhr.responseText)
    }
    xhr.send(JSON.stringify({data:{type:'getEventDetails', eventID, clientID}}))
  }
}

let retryAttempts = 0
export function connectToChat(payload) {
  return (dispatch, getState) => {
    let state = getState()
    if (!payload.eventID && (!state.chat.eventData.eventID && !state.chat.eventData.clientID)) {
      Logger.error('error connecting to chat without eventid', payload)
      return {type:'ERROR TRIED CONNECTING TO CHAT WITHOUT EVENTID', payload}
    } else {
      payload.eventID = state.chat.eventData.clientID + '_' + state.chat.eventData.eventID
    }
    dispatch({type: CHAT_ATTEMPT_CONNECTION})
    const combinedID = payload.eventID
    const wsBaseUrl = isDevEnv ? process.env.REACT_APP_TEST_WEBSOCKET_DOMAIN : process.env.REACT_APP_PROD_WEBSOCKET_DOMAIN
    const region = process.env.REACT_APP_REGION
    const stage = isDevEnv ? 'Test' : 'Prod'
    const url = `wss://${wsBaseUrl}.execute-api.${region}.amazonaws.com/${stage}/?eventId=${combinedID}`
    let healthChecker
    // eslint-disable-next-line no-undef
    const webSocket = new WebSocket(url)
    const reconnect = () => {
      if (retryAttempts < 5) {
        setTimeout(function() {
          dispatch(connectToChat(payload))
        }, 1000)
        retryAttempts++
      } else {
        dispatch({type: CHAT_ON_ERROR, error: 'Could not reconnect to the event. Check your internet connection, if the problem persists contact your administrator.'})
      }
    }
    webSocket.onerror = payload => {
      dispatch({type: CHAT_ON_ERROR, payload})
      Logger.error('websocket error:', payload)
      webSocket.close()
    }
    webSocket.onopen = () => {
      retryAttempts = 0
      dispatch({type: CHAT_ON_CONNECTION, webSocket})
      if (healthChecker) {
        clearInterval(healthChecker)
      }
      dispatch(sendMessage({type: CHAT_EVENT_DATA}))
      // dispatch(sendMessage({type: CHAT_CONNECTED_MEMBERS}))
      const heartbeatTimeMs = 300000
      healthChecker = setInterval(() => {
        dispatch(sendMessage({type: HEART_BEAT}))
      }, heartbeatTimeMs)
    }
    webSocket.onclose = payload => {
      dispatch({type: CHAT_ON_CLOSE, payload})
      Logger.warn('Websocket closed attempting to reconnect to websocket in 1 second')
      clearInterval(healthChecker)
      reconnect()
    }
    webSocket.onmessage = payload => {
      state = getState()
      if (payload.data === 'success') return
      const res = JSON.parse(payload.data)
      let type
      if (res.type) type = res.type
      dispatch({type: `${CHAT_RECIEVED_MESSAGE}_${type}`, payload:res})
      Logger.log('got message:', res)
      if (res.type) {
        const { data } = res
        switch (type) {
          case 'getUserResponse': {
            dispatch({type: GET_USER_RESPONSE, payload: data})
            break
          }
          case 'getEventMembersResponse': {
            dispatch({type: GET_MEMBERS_RESPONSE, payload: data})
            break
          }
          case 'userCreated': {
            if (data.email) {
              dispatch({type:'SET_THIS_USER', payload: {user:{...data}, from:data.from}})
              dispatch(actions.getMessages({email:data.email.toLowerCase()}))
            }
            dispatch({type: NEW_CHAT_USER, payload: data})
            break
          }
          case 'userOptionsUpdated': {
            dispatch({type: USER_UPDATE, payload: data})
            break
          }
          case 'userUpdated': {
            // check if user type changed
            // if it changed get messages again
            if (
              data.userId === state.members.thisUser &&
              data.type !== state.members.data[state.members.thisUser].type
            ) {
              dispatch({type: GET_MESSAGES_REQUEST, payload:{
                userId: data.userId,
                sub:state.members.data[state.members.thisUser].sub
              }})
              dispatch(sendMessage({
                type: GET_MESSAGES,
                data: {
                  userId: data.userId,
                  sub:state.members.data[state.members.thisUser].sub
                }
              }))
            }
            dispatch({type: USER_UPDATE, payload: data})
            break
          }
          case 'getMessagesResponse': {
            dispatch({type: GET_MESSAGES_RESPONSE, payload: data})
            dispatch(sendMessage({type: CHAT_CONNECTED_MEMBERS}))
            break
          }
          case 'messageCreated': {
            dispatch({type: MESSAGE_CREATED, payload: data})
            break
          }
          case 'messageUpdated': {
            dispatch({type: MESSAGE_UPDATED, payload: data})
            break
          }
          case 'messageReplied': {
            dispatch({type: ON_MESSAGE_REPLY, payload: data})
            break
          }
          case 'pollVoteResponse': {
            dispatch({type: MESSAGE_UPDATED, payload: data})
            break
          }
          case 'chatMembers': {
            dispatch({type: CHAT_MEMBERS_RESPONSE, payload: data})
            break
          }
          case 'getUploadUrlResponse': {
            dispatch(actions.uploadFile(data))
            break
          }
          case 'getDownloadUrlResponse': {
            dispatch({type: SET_MESSAGE_ATTACHMENT_URL, payload: data})
            break
          }
          case 'reconnectUsers': {
            dispatch(disconnectFromChat())
            break
          }
          case 'newChatSettings':
            // handled the same as chat event data
            // eslint-disable-next-line no-fallthrough
          case 'chat_event_data': {
            dispatch({type: CHAT_EVENT_DATA, payload: data})
            break
          }
          case 'unauthorizedUser':{
            // show notification of unathorized attempt
            break
          }
          case 'heartBeatResponse':{
            dispatch({type: HEART_BEAT, payload:{}})
            break
          }
          case 'connectionClosed': {
            reconnect()
            break
          }
          default: {
            Logger.warn('message type isn\'t handled', res)
          }
        }
      } else {
        if (res.message && res.message === 'Internal server error') {
          Logger.error('internal server error ')
          dispatch({type: INTERNAL_SERVER_ERROR, payload: res})
        } else {
          Logger.warn('no websocket response message type:', res)
        }
      }
    }
  }
}
