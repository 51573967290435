import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import actions from '../../../../lib/actions'

const useStyles = makeStyles(() => (
  {
    attachmentLink: {
      padding: 0,
      width: '100%'
    }
  }
))

export const UnconnectedItem = props => {
  const { url, fileName, assetId, messageId, getDownloadUrl } = props
  const classes = useStyles()
  if (!url) {
    getDownloadUrl(assetId, messageId, fileName)
  }
  if (!url || url === 'pending') {
    return <Button key={assetId + 'attachment loading'} className={classes.attachmentLink}>Loading...</Button>
  } else {
    // @TODO: find a way to open this without opening a new page
    // might need to append an iframe to the body and open the link in that frame
    // removing target=_blank loads in the same page but then reloads/rerenders the page and causes loading crash
    return <a
        style={{width:'100%', padding:'0', height:'100%'}}
        value={url}
        key={fileName + ' download' + messageId}
        href={url}
        target='_blank'
        rel='noopener noreferrer'
        download={fileName}
        tabIndex={0}
      >
        <Button className={classes.attachmentLink}>
          {fileName}
        </Button>
      </a>
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDownloadUrl: (assetId, messageId, fileName) => {
      dispatch(actions.getDownloadFileUrl({assetId, messageId, fileName}))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { assetId, messageId } = ownProps
  const attachment = state.messages.data[messageId].content.attachments.filter(attachment => attachment.assetId === assetId)[0]
  const url = attachment.url
  const fileName = attachment.fileName
  return { url, fileName }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedItem)

