import {
  CHANGE_CONTENT
} from './types'
import constants from '../../lib/constants'

const getInitialState = () => (
  {
    content: constants.CONTENT.MESSAGES
  }
)

const reducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case CHANGE_CONTENT:
      return Object.assign({}, state, {content: action.payload.content})
  }
  return state
}

export default reducer
