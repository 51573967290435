import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from '@material-ui/core'

import selectors from '../../store/selectors'
import actions from '../../lib/actions'

export const UnconnectedChangeNameButton = props => {
  const { name, updateName, setOpenLock } = props
  const [isOpen, setOpen] = useState(false)
  const [newName, setNewName] = useState(name)
  const handleClose = () => {
    setOpenLock(false)
    setOpen(false)
  }
  return (
      <div>
        <Button
          key='changeNameButton'
          variant='contained'
          color='primary'
          onClick={() => {
            setOpenLock(true)
            setOpen(!isOpen)
          }}
        >
          Change Name
        </Button>
        <Dialog
          open={isOpen}
          onClose={() => handleClose()}
          fullWidth
        >
          <DialogTitle
            id='form-dialog-title'
          >
            Change Name
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Current display name: {name}
            </DialogContentText>
            <TextField
              onChange={e => setNewName(e.target.value)}
              autoFocus
              id='name'
              label='Name'
              type='text'
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              onClick={() => {
                updateName(newName)
                handleClose()
              }}
            >
              Update
            </Button>
            <Button
              color='primary'
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  )
}

const mapStateToProps = state => {
  return {state}
}

const mapDispatchToProps = dispatch => {
  return {dispatch}
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { state } = stateProps
  const { dispatch } = dispatchProps
  const { setOpenLock } = ownProps
  const thisUser = selectors.getCurrentMember(state)
  const name = thisUser.name
  const updateName = newName => {
    thisUser.displayName = newName
    thisUser.name = newName
    dispatch(actions.updateUser(thisUser))
  }
  return { name, updateName, setOpenLock }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UnconnectedChangeNameButton)
