import React, { useState, useRef } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import MenuList from '@material-ui/core/MenuList'

import actions from '../../../lib/actions'
import selectors from '../../../store/selectors'

const useStyles = makeStyles(() => ({
  optionButton: {
    margin: 'auto -1rem',
    padding: '0.5rem',
    textAlign: 'center'
  }
}))

export const UnconnectedOptions = props => {
  const {
    messageId,
    showEdit,
    message,
    deleteMessage
  } = props
  const classes = useStyles()
  const [showPop, setShowPop] = useState(false)
  const anchorRef = useRef(null)

  return (
    <div>
      <IconButton
        aria-label='option button'
        ref={anchorRef}
        className={classes.optionButton}
        onClick={() => setShowPop(true)}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={showPop}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='right'
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right center',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setShowPop(false)}>
                <MenuList>
                <MenuItem
                    onClick={() => {
                      setShowPop(false)
                      showEdit(messageId)
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      deleteMessage(message)
                      setShowPop(false)
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>

  )
}

const mapDispatchToProps = dispatch => {
  return {
    showEdit: messageId => {
      dispatch(actions.changeShowEdit({showEdit:messageId}))
    },
    deleteMessage: message => {
      dispatch(actions.deleteMessage({message}))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const message = selectors.getMessageByID(state, ownProps.messageId)
  return { message }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnconnectedOptions)
