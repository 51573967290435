import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import Avatar from '@material-ui/core/Avatar'
import Spinner from 'react-bootstrap/Spinner'
import LoadIcon from '@material-ui/icons/SystemUpdateAlt';

import Message from './message'
import selectors from '../../../store/selectors'
import actions from '../../../lib/actions'
import constants from '../../../lib/constants'
import Options from './options'
import { IconButton, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(() => {
  const duration = '0.2s'
  const tType = 'ease'
  return {
    root: constants.STYLES.MESSAGE_CONTAINER_ROOT,
    reverse: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
    container: {
      paddingTop: '1rem',
      marginTop: '-1rem',
      borderRadius: '1rem',
      position: 'relative',
      '&:last-child hr': {
        display: 'none'
      }
    },
    sideCol: {
      margin: 'auto',
      textAlign: 'center',
      maxWidth: '3rem',
      minWidth: '1rem',
    },
    divider: {
      marginTop: '0'
    },
    image: {
      height: '2rem',
      width: '2rem'
    },
    negativeLeft: {
      marginLeft: '-0.4rem'
    },
    negativeRight: {
      marginRight: '-0.4rem'
    },
    selectableMessage: {
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.02)',
        filter: 'brightness(98%)',
        cursor: 'pointer'
      },
      '-webkit-transition': `${duration} -webkit-filter ${tType}`,
      '-moz-transition': `${duration} -moz-filter ${tType}`,
      'transition': `${duration} filter ${tType}`,
      '-ms-transition': `${duration} -ms-filter ${tType}`,
      '-o-transition': `${duration} -o-filter ${tType}`,
      transitionProperty: 'filter, background-color',
      transitionDuration: duration,
      transitionTimingFunction: tType
    },
    message: {
      padding: '1rem 0',
      borderRadius: '1rem',
      marginBottom: '1px'
    },
    savedMessage: {
      backgroundColor: 'rgba(0,0,0,0.08)'
    },
    selectedMessage: {
      backgroundColor: 'rgba(0,0,0,0.08) !important'
    },
    loadButton: {
      position: 'absolute',
      display: 'flex',
      marginLeft: '0.5rem',
      top: 'calc(50% - 0.5rem)',
      zIndex: 1
    }
  }
})

const elementAtBottom = (element, buffer) => {
  return element && element.scrollHeight - element.clientHeight <= element.scrollTop + buffer
}

const SavedNotice = props => {
  const { classes, messageID } = props
  const dispatch = useDispatch()
  const loadMessageIntoSubmission = () => dispatch(actions.loadMessageIntoSubmission({messageID}))
  return <div className={classes.loadButton}>
    <Tooltip title="This message is hidden, click here load a copy of this message to submit">
      <IconButton size="small" onClick={loadMessageIntoSubmission}>
        <LoadIcon />
      </IconButton>
    </Tooltip>
  </div>
}

const MessageContainer = props => {
  const {
    messages,
    thisUser,
    loaded,
    members,
    selectMessage,
    selectedMessage,
    room,
    numMessages
  } = props
  const classes = useStyles()
  const containerRef = useRef(null)
  const [scrolled, setScrolled] = useState(false)
  const [atBottom, setAtBottom] = useState(false)
  const bufferPx = 100

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scroll({top: containerRef.current.scrollHeight, behavior:'smooth'})
      setScrolled(true)
    }
  }, [room])

  useEffect(() => {
    if (containerRef && containerRef.current && !elementAtBottom(containerRef.current, 0) && atBottom) {
      //should be at bottom but isn't so scroll
      containerRef.current.scroll({top: containerRef.current.scrollHeight, behavior:'smooth'})
      setScrolled(true)
    }
  }, [numMessages, atBottom])


  //scroll on load
  if (!scrolled && containerRef && containerRef.current) {
    containerRef.current.scroll({top: containerRef.current.scrollHeight, behavior:'smooth'})
    setScrolled(true)
  }

  if (!loaded) return <Spinner />
  return (
    <div
      className={classes.root}
      ref={containerRef}
      onScroll={() => {
        if (containerRef.current) {
          setAtBottom(!!elementAtBottom(containerRef.current, bufferPx))
        }
      }}
    >
      {messages.map(message => {
        // if a member is deleted from the db all of their messages will have no related messageUser
        const messageUser = members[message.memberID]
        const isByThisUser = thisUser && messageUser && thisUser.userId === messageUser.userId
        const avatarSrc = messageUser && messageUser.picture
        return <SingleMessageContainer
          key={message.id}
          classes={classes}
          message={message}
          messageUser={messageUser}
          isByThisUser={isByThisUser}
          avatarSrc={avatarSrc}
          thisUser={thisUser}
          selectMessage={selectMessage}
          selectedMessage={selectedMessage}
        />
      })}
    </div>
  )
}

const SingleMessageContainer = props => {
  const {
    classes,
    message,
    messageUser,
    isByThisUser,
    avatarSrc,
    thisUser,
    selectMessage,
    selectedMessage
  } = props
  return (
      <div className={classes.container}>
        {message.options.saved ? <SavedNotice classes={classes} messageID={message.id}/> : null}
        <Grid
          container item xs={12}
          className={clsx(
            classes.message,
            isByThisUser && classes.reverse,
            thisUser && thisUser.type === constants.MEMBER_TYPES.MODERATOR && !isByThisUser && classes.selectableMessage,
            selectedMessage === message.id && classes.selectedMessage,
            message.options.saved && classes.savedMessage
          )}
          onClick={() => {
            if (thisUser.type === constants.MEMBER_TYPES.MODERATOR) {
              selectMessage(message.id)
            }
          }}
        >
          <Grid item xs={1} className={classes.sideCol}>
            <Avatar alt={messageUser && messageUser.name} src={avatarSrc} className={clsx(classes.sideCol, classes.image, !isByThisUser && classes.negativeLeft, isByThisUser && classes.negativeRight)}/>
          </Grid>
          <Grid item sm={9} md={10}>
            <Message messageID={message.id} />
          </Grid>
          <Grid item xs={1} className={classes.sideCol}>
            {thisUser && messageUser && messageUser.userId === thisUser.userId ? <Options messageId={message.id}/> : null}
          </Grid>
        </Grid>
        <Divider variant='middle' className={classes.divider}/>
      </div>
  )
}

const sortMessages = messages => {
  const sorted = messages.sort((a, b) => a.timePosted - b.timePosted)
  return sorted
}

const mapDispatchToProps = dispatch => {
  return {
    selectMessage: messageID => {
      dispatch(actions.selectMessage({messageID}))
    }
  }
}

const mapStateToProps = state => {
  const loaded = state.messages.loaded && state.members.loaded
  const messages = sortMessages(selectors.getCurrentRoomMessages(state)).filter(msg => {
    return !msg.options.deleted
  })
  const thisUser = selectors.getCurrentMember(state)
  const members = Object.assign({}, state.members.data)
  const selectedMessage = state.messages.selectedMessage
  const numMessages = messages.length
  const room = thisUser && thisUser.currentRoom || constants.CHAT.GENERAL
  return { messages, thisUser, loaded, members, selectedMessage, numMessages, room }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer)