import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import csvStringify from 'csv-stringify'

import constants from '../../lib/constants'

// Function to download data to a file
export const download = (data, filename, type) => {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob) // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else { // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

const Export = props => {
  const { onSave, fileName, csvData, headers } = props
  
  useEffect(() => {
    csvStringify(
      csvData,
      {
        header: true,
        headers
      }, (err, data) => {
        if (err) {
          console.error('Error downloading chat messages:', err)
        }
        download(data, fileName, 'text/csv')
        onSave()
      }
    )
  }, [])
  return null
}

const mapStateToProps = state => {
  // const need messages ordered by time
  // message: {time, email, userName, body, isAdminMessage, to}
  const headers = ['Time', 'From Email', 'From Name', 'Message', 'From Admin', 'To User Name', 'To User Email', 'poll']
  const { messages, members } = state
  const csvData = Object.keys(messages.data).map(messageKey => {
    const message = messages.data[messageKey]
    const time = new Date(message.timePosted).toString()
    const fromUser = members.data[message.memberID] || {
      email: 'undefined',
      name: 'unknown / deleted',
      userID: message.memberID,
      type: constants.MEMBER_TYPES.MEMBER
    }
    let toUser
    if (members.data[message.to]) {
      toUser = {
        email:members.data[message.to].email,
        name: members.data[message.to].name
      }
    } else {
      toUser = {
        email: 'ROOM',
        name: message.to
      }
    }
    const body = message.content.message
    const isAdminMessage = fromUser.type === constants.MEMBER_TYPES.MODERATOR || fromUser.type === constants.MEMBER_TYPES.HOST
    let poll = ''
    if (message.content.poll) {
      message.content.poll.types.forEach((type, i) => {
        poll += `"${type}, ${message.content.poll.values[i]}"`
      })
    }
    return {
      Time: time,
      'From Email': fromUser.email,
      'From Name': fromUser.name,
      Message: body,
      'From Admin': isAdminMessage == 1 ? 'True' : 'False',
      'To User Name': toUser.name,
      'To User Email': toUser.email,
      poll
    }
  }).filter(message => {
    // ignore messages to self
    return message['From Email'] !== message['To User Email']
  })
  const fileName = 'Chat Messages ' + state.chat.eventData.combinedID + ' ' + state.chat.eventData.startDateTime + '.csv'
  return { csvData, fileName, headers }
}

export default connect(mapStateToProps)(Export)
